<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>巡检路线管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="8">
        <el-card>
          <div slot="header">
            <span>巡检路线</span>
            <el-button v-has="'sys:pc:type:edit'" style="float: right; padding: 3px 0" type="text" @click="showAddDialog">添加路线</el-button>
          </div>

          <!-- 表格数据 -->
          <div class="dataTable" ref="tableBox">
          <el-table :data="tableInfoList" border stripe @row-click="selectDzRow" v-if="tableHeight"
          :max-height="tableHeight + 'px'">
            <el-table-column label="颜色" width="60px">
              <template slot-scope="scope">
                <el-color-picker v-model="scope.row.lineColor" ></el-color-picker>
              </template>
            </el-table-column>
            <el-table-column label="级别" prop="roleName" width="80px"></el-table-column>
            <el-table-column label="路线名称" prop="lineName"></el-table-column>
            <el-table-column label="操作" width="120px">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="修改"
                  placement="top"
                  :enterable="false"
                >
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    v-has="'sys:pc:type:edit'"
                    @click="showEditDialog(scope.row.id)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="删除"
                  placement="top"
                  :enterable="false"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    v-has="'sys:pc:type:edit'"
                    @click="removeById(scope.row.id)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页组件 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
            style="right:0;bottom:0;position:absolute;"
          >
          </el-pagination>
          </div>


          <!-- 添加巡检路线 -->
          <el-dialog
            title="添加巡检路线"
            :visible.sync="addDialogVisible"
            width="50%"
            v-if="addDialogVisible"
            @close="addDialogClosed"
          >
            <el-form :model="dataFormObj" :rules="formRules" ref="addInfoRef">
              <el-form-item label="巡检级别" label-width="120px" prop="roleCode">
                <el-select v-model="dataFormObj.roleCode" placeholder="请选择级别" clearable>
                  <el-option
                    v-for="item in checkLevelList"
                    :key="item.roleCode"
                    :label="item.roleName"
                    :value="item.roleCode">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="名称" label-width="120px" prop="lineName">
                <el-input
                  autocomplete="off"
                  v-model="dataFormObj.lineName"
                  clearable
                  placeholder="最多输入50个字"
                ></el-input>
              </el-form-item>
              <el-form-item label="路线颜色" label-width="120px" prop="lineColor">
                <el-color-picker v-model="dataFormObj.lineColor"></el-color-picker>
              </el-form-item>
              <el-form-item label="备注" label-width="120px" prop="remark">
                <el-input
                  type="textarea"
                  v-model="dataFormObj.remark"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="addDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
            </span>
          </el-dialog>



          <!-- 编辑巡检路线 -->
          <el-dialog
            title="编辑巡检路线"
            :visible.sync="editDialogVisible"
            width="50%"
            v-if="editDialogVisible"
          >
            <el-form :model="dataFormObj" :rules="formRules" ref="editInfoRef">
              <el-form-item label="巡检级别" label-width="120px" prop="roleCode">
                <el-select v-model="dataFormObj.roleCode" placeholder="请选择级别" clearable>
                  <el-option
                    v-for="item in checkLevelList"
                    :key="item.roleCode"
                    :label="item.roleName"
                    :value="item.roleCode">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="名称" label-width="120px" prop="lineName">
                <el-input
                  autocomplete="off"
                  v-model="dataFormObj.lineName"
                  clearable
                  placeholder="最多输入50个字"
                ></el-input>
              </el-form-item>
              <el-form-item label="路线颜色" label-width="120px" prop="lineColor">
                <el-color-picker v-model="dataFormObj.lineColor"></el-color-picker>
              </el-form-item>
              <el-form-item label="备注" label-width="120px" prop="remark">
                <el-input
                  type="textarea"
                  v-model="dataFormObj.remark"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="editDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="editInfoSubmit()">提 交</el-button>
            </span>
          </el-dialog>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card>
          <div slot="header">
            <span>巡检项</span>
            <el-button v-has="'sys:pc:item:edit'" style="float: right; padding: 3px 0; margin-left: 20px;" type="text" @click="showLineMap">路线优化</el-button>
            <el-button v-has="'sys:pc:item:edit'" style="float: right; padding: 3px 0" type="text" @click="showAddItemDialog">添加巡检项</el-button>
          </div>
          <div class="select-gnss">
            <el-table :data="checkItemList" border stripe v-if="tableHeight"
              :max-height="tableHeight + 'px'">
              <el-table-column type="index"></el-table-column>
              <!-- <el-table-column label="所属路线" prop="lineName" width="180px"></el-table-column> -->
              <el-table-column label="顺序" prop="lineSort" width="60px"></el-table-column>
              <el-table-column label="巡检项名" prop="itemName" width="180px"></el-table-column>
              <el-table-column label="巡检内容" prop="checkContent"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" content="删除" placement="top" :enterable="false">
                    <el-button v-has="'sys:pc:item:edit'" type="danger" icon="el-icon-delete" @click="removeItemById(scope.row)"></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            
            <!-- 分页组件 -->
            <el-pagination
              @size-change="handleItemSizeChange"
              @current-change="handleItemCurrentChange"
              :current-page="queryItemParams.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="queryItemParams.pageSize"
              layout="total, sizes, prev, pager, next"
              :total="totalItem"
              style="right:0;bottom:0;position:absolute;"
            >
            </el-pagination>
          </div>

           <!-- 添加巡检项 -->
           <el-dialog
            title="添加巡检项"
            :visible.sync="addItemDialogVisible"
            width="50%"
            v-if="addItemDialogVisible"
            @close="addItemDialogClosed"
          >
            <el-form :model="itemDataForm" :rules="itemFormRules" ref="addItemInfoRef">
              <el-form-item label="巡检项" label-width="120px" prop="itemCode">
                <el-select
                  v-model="itemDataForm.itemCode"
                  placeholder="请选择巡检项"
                  clearable
                  @change="selectCheckItem"
                >
                  <el-option
                    v-for="item in unlinCheckItemList"
                    :key="item.itemCode"
                    :label="item.itemName"
                    :value="item.itemCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="排序号" label-width="120px">
                <el-row>
                  <el-col :span="18">
                    <el-input
                      autocomplete="off"
                      v-model="resourceOrder"
                      disabled
                    ></el-input>
                  </el-col>
                  <el-col :span="6">
                    <el-button-group>
                      <el-button icon="el-icon-minus" @click="decrease"></el-button>
                      <el-button icon="el-icon-plus" @click="increase"></el-button>
                    </el-button-group>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="addItemDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="addItemInfoSubmit()">提 交</el-button>
            </span>
          </el-dialog>

          <!-- 优化巡检路线 -->
          <el-dialog
            title="优化巡检路线"
            :visible.sync="showCheckLineMapVisible"
            width="70%"
            v-if="showCheckLineMapVisible"
          >
            <el-button type="primary" style="margin-bottom: 15px;" @click="saveLineInfo">保存路线</el-button>
            <el-button type="danger" style="margin-bottom: 15px;" @click="resetLineInfo">重置路线</el-button>
            <div style="height: 600px; width: 100%;">
              <v-pcs-lines-map ref="lineMapRef" :data="currentSelectRow" :canEdit="true"></v-pcs-lines-map>
            </div>
          </el-dialog>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import vPcsLinesMap from "./PcsLinesMap.vue";
export default {
  components: {
    vPcsLinesMap
  },
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      addItemDialogVisible: false,
      showCheckLineMapVisible: false,
      tableInfoList: [],
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      queryItemParams: {
        typeCode: "",
        lineCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      totalItem: 0,
      formRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        roleCode: [{ required: true, message: "请选择巡检级别", trigger: "blur" }],
        lineColor: [{ required: true, message: "请选择路线颜色", trigger: "blur" }],
        lineName: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      dataFormObj: {},
      projectInfoList: [],
      currentSelectRow: {},
      currentSelectItem: {},
      tableHeight:0,
      checkItemList: [],
      unlinCheckItemList: [],
      itemDataForm: {},
      itemFormRules: {
        itemCode: [{ required: true, message: "请选择巡检项", trigger: "blur" }],
      },
      resourceOrder: 1,
    }
  },
  created() {
    this.getAllProject();
    this.getCheckLevelList();
  },
  mounted(){
    this.tableHeight = this.$refs.tableBox.clientHeight - 40;
  },
  methods: {
    // 公共方法区
    decrease() {
      if (this.resourceOrder > 1) {
        this.resourceOrder--;
      }
    },
    increase() {
      this.resourceOrder++;
    },
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
        if (this.projectInfoList != null) {
        this.queryParams.projectCode = this.projectInfoList[0].projectCode;
      }
      this.getTableDataList();
      } else {
        this.$message.error(res.msg);
      }
    },
    async getCheckLevelList() {
      const { data: res } = await this.$http.get("role/find/check/level");
      if (res.code == 0) {
        this.checkLevelList = res.data;
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getTableDataList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getTableDataList();
    },
    handleItemSizeChange(newSize) {
      this.queryItemParams.pageSize = newSize;
      this.getCheckItemList();
    },
    handleItemCurrentChange(newPage) {
      this.queryItemParams.pageNo = newPage;
      this.getCheckItemList();
    },
    projectCodeChange(projectCode) {
      this.queryParams.projectCode = projectCode;
      this.getTableDataList();
    },
    async getTableDataList() {
      const { data: res } = await this.$http.get("pro/check/line/find/byPage", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.tableInfoList = [];
          return;
        }
        this.tableInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    showAddDialog() {
      this.dataFormObj = {};
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addInfoRef.resetFields();
    },
    showAddItemDialog() {
      this.itemDataForm = {};
      this.getUnlineCheckItemList();
      this.addItemDialogVisible = true;
    },
    addItemDialogClosed() {
      this.$refs.addItemInfoRef.resetFields();
    },
    async addInfoSubmit() {
      this.dataFormObj.projectCode = this.queryParams.projectCode;
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "pro/check/line/add",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showEditDialog(id) {
      this.dataFormObj = {};
      const { data: res } = await this.$http.get("pro/check/line/find/one/" + id);
      if (res.code == 0) {
        this.dataFormObj = res.data;
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async editInfoSubmit() {
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "pro/check/line/edit",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该类型吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("pro/check/line/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getTableDataList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    selectDzRow(row, column, event) {
      this.currentSelectRow = row;
      this.getCheckItemList();
    },
    async getCheckItemList() {
      this.queryItemParams.lineCode = this.currentSelectRow.lineCode;
      const { data: res } = await this.$http.get("pro/check/item/find/byline", {
        params: this.queryItemParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.checkItemList = [];
          return;
        }
        this.checkItemList = res.data.list;
        this.totalItem = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getUnlineCheckItemList() {
      this.queryItemParams.lineCode = this.currentSelectRow.lineCode;
      const { data: res } = await this.$http.get("pro/check/item/find/unline", {
        params: {
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.unlinCheckItemList = [];
          return;
        }
        this.unlinCheckItemList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async addItemInfoSubmit() {
      if (!this.currentSelectRow.lineCode) {
        this.$message.error('请选择路线');
        return;
      }
      this.itemDataForm.itemCode = this.currentSelectItem.itemCode;
      this.itemDataForm.lineCode = this.currentSelectRow.lineCode;
      this.itemDataForm.projectCode = this.currentSelectRow.projectCode;
      this.itemDataForm.lineSort = this.resourceOrder;
      this.$refs.addItemInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "pro/check/line/add/items",
            this.itemDataForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getCheckItemList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addItemDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeItemById(data) {
      const confirmResult = await this.$confirm("是否从路线中删除该巡检项吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("pro/check/line/remove/item", {
        params: {
          lineCode: data.lineCode,
          itemCode: data.itemCode
        }
      }
      );
      if (res.code == 0) {
        this.$message.success("删除成功");
        this.getCheckItemList();
      } else {
        this.$message.error(res.msg);
        return false;
      }
    },
    selectCheckItem(data) {
      this.currentSelectItem = this.getItem(data);
    },
    getItem(code) {
      var pro = null;
      this.unlinCheckItemList.forEach(p => {
        if (code === p.itemCode) {
          pro = p;
        }
      });
      return pro;
    },
    showLineMap() {
      this.showCheckLineMapVisible = true;
    },
    saveLineInfo() {
      this.$refs.lineMapRef.saveLinePoint();
    },
    resetLineInfo() {
      this.$refs.lineMapRef.resetLineInfo();
    }
  }
}
</script>
  
<style lang="less" scoped>
    .dataTable {
      width: 100%;
      height: calc(100vh - 270px);
      position: relative;
    }
  
    .select-gnss {
      height: calc(100vh - 270px);
      position: relative;
    }
  </style>