import { render, staticRenderFns } from "./Repair.vue?vue&type=template&id=497a30d4&scoped=true&"
import script from "./Repair.vue?vue&type=script&lang=js&"
export * from "./Repair.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "497a30d4",
  null
  
)

export default component.exports