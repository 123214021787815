<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>报表统计</el-breadcrumb-item>
            <el-breadcrumb-item>巡检完成度</el-breadcrumb-item>
        </el-breadcrumb>

        <el-card>
            <el-row :gutter="5">
                <el-col :span="3">
                    <el-select v-model="selectYear" placeholder="请选择年份">
                        <el-option label="2023" :value="2023"></el-option>
                        <el-option label="2024" :value="2024"></el-option>
                        <el-option label="2025" :value="2025"></el-option>
                        <el-option label="2026" :value="2026"></el-option>
                        <el-option label="2027" :value="2027"></el-option>
                        <el-option label="2028" :value="2028"></el-option>
                        <el-option label="2029" :value="2029"></el-option>
                        <el-option label="2030" :value="2030"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <el-select v-model="selectMonth" placeholder="请选择月份">
                        <el-option label="1月" :value="1"></el-option>
                        <el-option label="2月" :value="2"></el-option>
                        <el-option label="3月" :value="3"></el-option>
                        <el-option label="4月" :value="4"></el-option>
                        <el-option label="5月" :value="5"></el-option>
                        <el-option label="6月" :value="6"></el-option>
                        <el-option label="7月" :value="7"></el-option>
                        <el-option label="8月" :value="8"></el-option>
                        <el-option label="9月" :value="9"></el-option>
                        <el-option label="10月" :value="10"></el-option>
                        <el-option label="11月" :value="11"></el-option>
                        <el-option label="12月" :value="12"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <el-select v-model="selectQuarter" placeholder="请选择季度">
                        <el-option label="一季度(1月-3月)" :value="1"></el-option>
                        <el-option label="二季度(4月-6月)" :value="2"></el-option>
                        <el-option label="三季度(7月-9月)" :value="3"></el-option>
                        <el-option label="四季度(10月-12月)" :value="4"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="5">
                    <el-radio-group v-model="selectType">
                        <el-radio :label="1">按年</el-radio>
                        <el-radio :label="2">按月</el-radio>
                        <el-radio :label="3">按季度</el-radio>
                    </el-radio-group>
                </el-col>
                <el-col :span="7">
                    <el-button type="primary" icon="el-icon-search" @click="loadData">查询</el-button>
                </el-col>
            </el-row>


            <el-row>
                <div ref="checkFinishChart" style="height: 500px; width: 100%"></div>
            </el-row>
            <el-row>
                <!-- 表格数据 -->
                <el-table :data="reportDataList" border stripe>
                    <el-table-column label="级别" prop="roleName"></el-table-column>
                    <el-table-column label="应巡检项" prop="checkTotal"></el-table-column>
                    <el-table-column label="已巡检项" prop="checked"></el-table-column>
                    <el-table-column label="完成度">
                        <template slot-scope="scope">
                            {{ common.floatFormat(common.floatFormat((scope.row.checked / scope.row.checkTotal), 2) * 100, 2) }}%
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            checkFinishChart: null,
            selectYear: null,
            selectMonth: null,
            selectQuarter: 1,
            selectType: 2,
            reportDataList: [],
            currentProjectInfo: null
        }
    },
    created() {
        this.getAllProject();
        this.selectYear = (new Date()).getFullYear();
        this.selectMonth = (new Date()).getMonth() + 1
    },
    methods: {
        async getAllProject() {
            const { data: res } = await this.$http.get("project/find/all");
            if (res.code == 0) {
                this.projectInfoList = res.data;
                if (this.projectInfoList != null) {
                    this.currentProjectInfo = this.projectInfoList[0];
                    this.loadData();
                }
            }
        },
        async loadData() {
            const { data: res } = await this.$http.get("report/check/finish", {
                params: {
                    projectCode: this.currentProjectInfo.projectCode,
                    year: this.selectYear,
                    month: this.selectMonth,
                    quarter: this.selectQuarter,
                    type: this.selectType
                }
            });
            if (res.code == 0) {
                this.reportDataList = res.data;
                this.drawView(this.reportDataList);
            } else {
                this.$message.error(res.msg);
            }
        },
        drawView(dataList) {
            let that = this;
            if (this.checkFinishChart == null) {
                this.checkFinishChart = this.$echarts.init(this.$refs.checkFinishChart);
                this.checkFinishChart.on('click', function (params) {
                    that.toPage(params.name);
                });
            }

            if (dataList === null || dataList.length === 0) {
                this.$message.error("无数据");
                // return;
                dataList = [];
            }

            var checkData = [];
            var checkTotalData = [];
            var checkX = [];
            dataList.forEach(data => {
                checkData.push(data.checked);
                checkTotalData.push(data.checkTotal);
                checkX.push(data.roleName);
            });

            var option;

            option = {
                title: {
                    top: 30,
                    left: 'center',
                    text: this.getTitle() + '巡检完成度统计'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    top: 60,
                    data: ['已巡检项', '应巡检项']
                },
                grid: {
                    left: 40,
                    right: 40,
                    top: 100,
                },
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: { show: true }
                    }
                },
                xAxis: [
                    {
                        name: '级别',
                        type: 'category',
                        data: checkX
                    }
                ],
                yAxis: [
                    {
                        name: '巡检项',
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '已巡检项',
                        type: 'bar',
                        data: checkData,
                        itemStyle: {
                            color: '#91cc75'
                        },
                        label: {
                            show: true
                        }
                    },
                    {
                        name: '应巡检项',
                        type: 'bar',
                        data: checkTotalData,
                        label: {
                            show: true
                        }
                    }
                ]
            };

            this.checkFinishChart.clear();
            option && this.checkFinishChart.setOption(option);
        },
        toPage(name) {
            this.$router.push({
                path: '/check/list',
                query: { name: name, year: this.selectYear, month: this.selectMonth, type: 1 },
            });
        },
        getTitle() {
            if (this.selectType === 1) {
                return this.selectYear + '年';
            } else if (this.selectType === 2) {
                return this.selectYear + '年' + this.selectMonth + '月';
            } else if (this.selectType === 3) {
                if (this.selectQuarter === 1) {
                    return this.selectYear + '年一季度';
                } else if (this.selectQuarter === 2) {
                    return this.selectYear + '年二季度';
                } else if (this.selectQuarter === 3) {
                    return this.selectYear + '年三季度';
                } else if (this.selectQuarter === 4) {
                    return this.selectYear + '年四季度';
                }
            }
        }
    }
}
</script>