<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>巡检记录</el-breadcrumb-item>
            <el-breadcrumb-item>巡检列表</el-breadcrumb-item>
        </el-breadcrumb>


        <!-- 卡片视图 -->
        <el-card>
            <el-row :gutter="10">
                <el-col :span="3">
                    <el-select v-model="queryParams.roleCode" placeholder="请选择级别" clearable>
                        <el-option v-for="item in checkLevelList" :key="item.roleCode" :label="item.roleName"
                            :value="item.roleCode">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <el-select v-model="queryParams.dgCode" placeholder="请选择风险类型" clearable>
                        <el-option v-for="item in dangerList" :key="item.dgCode" :label="item.dgName"
                            :value="item.dgCode">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <el-select v-model="queryParams.checkStatus" placeholder="请选择巡检状态" clearable>
                        <el-option label="未巡检" :value="0"></el-option>
                        <el-option label="暂存" :value="1"></el-option>
                        <el-option label="已巡检" :value="2"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="7">
                    <el-date-picker v-model="timeRange" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" @change="triggerDate">
                    </el-date-picker>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" icon="el-icon-search" @click="getDataList">查询</el-button>
                </el-col>
            </el-row>


            <!-- 表格数据 -->
            <el-table :data="dataInfoList" border stripe>
                <el-table-column type="index"></el-table-column>
                <el-table-column label="级别" prop="roleName"></el-table-column>
                <el-table-column label="巡检员" prop="userName"></el-table-column>
                <el-table-column label="实际巡检员" prop="realUn"></el-table-column>
                <el-table-column label="巡检项" prop="checkItemName"></el-table-column>
                <el-table-column label="巡检状态" prop="repairStatus">
                    <template slot-scope="scope">
                        <el-tag type="danger" v-if="scope.row.checkStatus === 0">未巡检</el-tag>
                        <el-tag v-else-if="scope.row.checkStatus === 1">暂存</el-tag>
                        <el-tag v-else type="success">已巡检</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="风险类型" prop="dgName"></el-table-column>
                <el-table-column label="开始时间" prop="createTime" width="160px"></el-table-column>
                <el-table-column label="巡检时间" prop="checkTime" width="160px"></el-table-column>
                <el-table-column label="巡检时长">
                    <template slot-scope="scope">
                        <span v-if="scope.row.checkTime != null && scope.row.checkTime != ''">
                            {{ common.timeSubtract(scope.row.createTime, scope.row.checkTime) }}
                        </span>
                        <span v-else>0</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120px">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="清除内容" placement="top" :enterable="false" v-has="'sys:check:log:clear'">
                            <el-button type="danger" icon="el-icon-delete" size="mini"
                                @click="clearContent(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="详情" placement="top" :enterable="false">
                            <el-button type="primary" icon="el-icon-document" size="mini"
                                @click="showDetail(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>


            <!-- 分页组件 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryParams.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size="queryParams.pageSize"
                layout="total, sizes, prev, pager, next" :total="total">
            </el-pagination>
        </el-card>


        <el-drawer v-if="drawer" title="详细信息" :visible.sync="drawer" size="40%" append-to-body>
            <v-check-detail ref="logdetailRef" :logdetail="selectLogdetail"></v-check-detail>
        </el-drawer>
    </div>
</template>
  
<script>
import vCheckDetail from "../checkdetail/CheckDetail.vue";
export default {
    components: {
        vCheckDetail
    },
    data() {
        return {
            drawer: false,
            queryParams: {
                projectCode: null,
                checkStatus: null,
                pageNo: 1,
                pageSize: 10,
                checkBeginDate: null,
                checkEndDate: null,
                roleCode: null,
                dgCode: null
            },
            total: 0,
            dataInfoList: [],
            timeRange: [],
            projectInfoList: [],
            selectLogdetail: {},
            checkLevelList: [],
            dangerList: [],
            isRouter: false
        }
    },
    created() {
        this.getAllProject();
    },
    mounted() {
        if (this.$route.query.name) {
            this.isRouter = true;
        } else {
            this.isRouter = false;
        }
    },
    methods: {
        async getStatisData() {
            let requestMerge = new Array();
            let r1 = await this.$http.get("role/find/check/level");
            let r2 = await this.$http.get("dtype/find/byPage", {
                params: {
                    projectCode: this.queryParams.projectCode,
                    pageNo: 1,
                    pageSize: 1000,
                },
            });
            requestMerge.push(r1);
            requestMerge.push(r2);
            let that = this;
            Promise.all(requestMerge).then(responseList => {
                console.log(responseList);
                let rs1 = responseList[0];
                let rs2 = responseList[1];
                if (rs1.data.code == 0) {
                    that.checkLevelList = rs1.data.data;
                    that.dangerList = rs2.data.data.list;
                    // setTimeout(() => {
                        if (that.isRouter) {
                            that.showFromReport(that.$route.query);
                        }
                    // }, 1000);
                    
                }
            });
        },
        triggerDate(date) {
            if (!this.common.triggerDate(date, 90)) {
                this.$message.error('查询时间不能超过90天！');
                return false;
            } else {
                return true;
            }
        },
        handleSizeChange(newSize) {
            this.queryParams.pageSize = newSize;
            this.getDataList();
        },
        handleCurrentChange(newPage) {
            this.queryParams.pageNo = newPage;
            this.getDataList();
        },
        async getAllProject() {
            const { data: res } = await this.$http.get("project/find/all");
            if (res.code == 0) {
                this.projectInfoList = res.data;
                if (this.projectInfoList != null) {
                    this.queryParams.projectCode = this.projectInfoList[0].projectCode;
                    this.getStatisData();
                    if (!this.isRouter) {
                        this.loadLastWeekData();
                    }
                }
            } else {
                this.$message.error(res.msg);
            }
        },
        // 获取过去一个星期的数据
        loadLastWeekData() {
            var today = new Date();
            // 天数所表示的毫秒
            var dateOffset = 30 * 24 * 60 * 60 * 1000;
            var startDate = new Date(today.getTime() - dateOffset);
            this.timeRange.push(new Date(Date.parse(this.common.todayDateStr('YYYY-MM-dd HH:mm:ss', startDate))));
            this.timeRange.push(new Date(Date.parse(this.common.todayDateStr('YYYY-MM-dd HH:mm:ss', today))));
            this.getDataList();
        },
        async getDataList() {
            if (!this.triggerDate(this.timeRange)) {
                return;
            }

            if (this.timeRange != null && this.timeRange.length > 0) {
                this.queryParams.checkBeginDate = this.common.dateFormat('YYYY-MM-dd', this.timeRange[0]);
                this.queryParams.checkEndDate = this.common.dateFormat('YYYY-MM-dd', this.timeRange[1]);
            } else {
                this.queryParams.checkBeginDate = null;
                this.queryParams.checkEndDate = null;
            }

            const { data: res } = await this.$http.post("pro/record/search", this.queryParams);
            if (res.code == 0) {
                this.dataInfoList = res.data.list;
                this.total = res.data.total;
            } else {
                this.$message.error(res.msg);
            }
        },
        showDetail(record) {
            this.findLogInfo(record);
        },
        async findLogInfo(data) {
            const { data: res } = await this.$http.get("pro/record/find/by/checkcode", {
                params: {
                    checkCode: data.checkRecordCode
                }
            });
            if (res.code == 0) {
                this.selectLogdetail = res.data;
                this.drawer = true;
            } else {
                this.$message.error(res.msg);
            }
        },
        showFromReport(params) {
            if (params.type === '1') {
                var roleCode = '';
                this.checkLevelList.forEach(lv => {
                    // console.log(lv);
                    // console.log(params.name);
                    if (lv.roleName === params.name) {
                        roleCode = lv.roleCode;
                    }
                });
                this.queryParams.roleCode = roleCode;
            } else {
                var dgCode = '';
                this.dangerList.forEach(lv => {
                    if (lv.dgName === params.name) {
                        dgCode = lv.dgCode;
                    }
                });
                this.queryParams.dgCode = dgCode;
            }
            
            const endOfMonth = new Date(params.year, params.month, 0).getDate();
            let month = params.month;
            let startDay = 1;
            let endDay = endOfMonth;
            if (month < 10) month = '0' + month
            if (startDay < 10) startDay = '0' + startDay
            if (endDay < 10) endDay = '0' + endDay
            var startTime = params.year + '-' + month + '-' + startDay + " " + "00:00:00";
            var endTime = params.year + '-' + month + '-' + endDay + " " + "00:00:00";
            this.timeRange.push(new Date(Date.parse(startTime)));
            this.timeRange.push(new Date(Date.parse(endTime)));
            this.getDataList();
        },
        async clearContent(record) {
            const confirmResult = await this.$confirm("是否确认清除该记录内容?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);
            // 点击确定 返回值为：confirm
            // 点击取消 返回值为： cancel
            if (confirmResult !== "confirm") {
                return this.$message.info("已取消");
            }
            const { data: res } = await this.$http.delete("pro/record/clear/info/" + record.id);
            if (res.code == 0) {
                this.$message.success("清除成功！");
                this.getDataList();
            } else {
                return this.$message.error("清除失败！");
            }
        }
    }
}
</script>
  
<style lang="less" scoped></style>