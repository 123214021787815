<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>巡检类型管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="8">
        <el-card>
          <div slot="header">
            <span>巡检类型</span>
            <el-button v-has="'sys:pc:type:edit'" style="float: right; padding: 3px 0" type="text" @click="showAddDialog">添加类型</el-button>
          </div>

          <!-- 表格数据 -->
          <div class="dataTable" ref="tableBox">
          <el-table :data="tableInfoList" border stripe @row-click="selectDzRow" v-if="tableHeight"
          :max-height="tableHeight + 'px'">
            <el-table-column type="index"></el-table-column>
            <el-table-column label="类型名称" prop="checkTypeName"></el-table-column>
            <!-- <el-table-column label="备注" prop="remark"></el-table-column> -->
            <el-table-column label="操作" width="120px">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="修改"
                  placement="top"
                  :enterable="false"
                >
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    v-has="'sys:pc:type:edit'"
                    @click="showEditDialog(scope.row.id)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="删除"
                  placement="top"
                  :enterable="false"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    v-has="'sys:pc:type:edit'"
                    @click="removeById(scope.row.id)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页组件 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
            style="right:0;bottom:0;position:absolute;"
          >
          </el-pagination>
          </div>


          <!-- 添加巡检类型 -->
          <el-dialog
            title="添加巡检类型"
            :visible.sync="addDialogVisible"
            width="50%"
            v-if="addDialogVisible"
            @close="addDialogClosed"
          >
            <el-form :model="dataFormObj" :rules="formRules" ref="addInfoRef">
              <!-- <el-form-item label="所属项目" label-width="120px" prop="projectCode">
                <el-select
                  v-model="dataFormObj.projectCode"
                  placeholder="请选择项目"
                  clearable
                >
                  <el-option
                    v-for="item in projectInfoList"
                    :key="item.projectCode"
                    :label="item.projectName"
                    :value="item.projectCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="名称" label-width="120px" prop="checkTypeName">
                <el-input
                  autocomplete="off"
                  v-model="dataFormObj.checkTypeName"
                  clearable
                  placeholder="最多输入50个字"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注" label-width="120px" prop="remark">
                <el-input
                  type="textarea"
                  v-model="dataFormObj.remark"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="addDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
            </span>
          </el-dialog>



          <!-- 编辑巡检类型 -->
          <el-dialog
            title="编辑巡检类型"
            :visible.sync="editDialogVisible"
            width="50%"
            v-if="editDialogVisible"
          >
            <el-form :model="dataFormObj" :rules="formRules" ref="editInfoRef">
              <!-- <el-form-item label="所属项目" label-width="120px" prop="projectCode">
                <el-select
                  v-model="dataFormObj.projectCode"
                  placeholder="请选择项目"
                  clearable
                >
                  <el-option
                    v-for="item in projectInfoList"
                    :key="item.projectCode"
                    :label="item.projectName"
                    :value="item.projectCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="名称" label-width="120px" prop="checkTypeName">
                <el-input
                  autocomplete="off"
                  v-model="dataFormObj.checkTypeName"
                  clearable
                  placeholder="最多输入50个字"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注" label-width="120px" prop="remark">
                <el-input
                  type="textarea"
                  v-model="dataFormObj.remark"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="editDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="editInfoSubmit()">提 交</el-button>
            </span>
          </el-dialog>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card>
          <div slot="header">
            <span>巡检项</span>
            <el-button v-has="'sys:pc:item:edit'" style="float: right; padding: 3px 0" type="text" @click="showAddItemDialog">添加巡检项</el-button>
          </div>
          <div class="select-gnss">
            <el-table :data="checkItemList" border stripe v-if="tableHeight"
              :max-height="tableHeight + 'px'">
              <el-table-column type="index"></el-table-column>
              <el-table-column label="巡检项名" prop="itemName" width="180px"></el-table-column>
              <el-table-column label="巡检内容" prop="checkContent"></el-table-column>
              <el-table-column label="操作" width="120px">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top" :enterable="false">
                    <el-button v-has="'sys:pc:item:edit'" type="primary" icon="el-icon-edit" @click="showItemEditDialog(scope.row.id)"></el-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="删除" placement="top" :enterable="false">
                    <el-button v-has="'sys:pc:item:edit'" type="danger" icon="el-icon-delete" @click="removeItemById(scope.row.id)"></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            
            <!-- 分页组件 -->
            <el-pagination
              @size-change="handleItemSizeChange"
              @current-change="handleItemCurrentChange"
              :current-page="queryItemParams.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="queryItemParams.pageSize"
              layout="total, sizes, prev, pager, next"
              :total="totalItem"
              style="right:0;bottom:0;position:absolute;"
            >
            </el-pagination>
          </div>

           <!-- 添加巡检项 -->
           <el-dialog
            title="添加巡检项"
            :visible.sync="addItemDialogVisible"
            width="50%"
            v-if="addItemDialogVisible"
            @close="addItemDialogClosed"
          >
            <el-form :model="itemDataForm" :rules="itemFormRules" ref="addItemInfoRef">
              <!-- <el-form-item label="所属项目" label-width="120px" prop="projectCode">
                <el-select
                  v-model="itemDataForm.projectCode"
                  placeholder="请选择项目"
                  clearable
                  @change="selectProject"
                >
                  <el-option
                    v-for="item in projectInfoList"
                    :key="item.projectCode"
                    :label="item.projectName"
                    :value="item.projectCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="巡检项名" label-width="120px" prop="itemName">
                <el-input
                  autocomplete="off"
                  v-model="itemDataForm.itemName"
                  clearable
                  placeholder="最多输入50个字"
                ></el-input>
              </el-form-item>
              <el-row :gutter="5">
                <el-col :span="11">
                  <el-form-item label="经度" label-width="120px" prop="itemLng">
                    <el-input
                      autocomplete="off"
                      v-model="itemDataForm.itemLng"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="纬度" label-width="60px" prop="itemLat">
                    <el-input
                      autocomplete="off"
                      v-model="itemDataForm.itemLat"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-button @click="addLngLatShowDialog" type="primary" class="el-icon-location-information">选择坐标</el-button>
                </el-col>
              </el-row>
              <el-form-item label="巡检内容" label-width="120px" prop="checkContent">
                <el-input
                  type="textarea"
                  v-model="itemDataForm.checkContent"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="addItemDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="addItemInfoSubmit()">提 交</el-button>
            </span>
          </el-dialog>



          <!-- 编辑巡检项 -->
          <el-dialog
            title="编辑巡检项"
            :visible.sync="editItemDialogVisible"
            width="50%"
            v-if="editItemDialogVisible"
          >
            <el-form :model="itemDataForm" :rules="itemFormRules" ref="editItemInfoRef">
              <!-- <el-form-item label="所属项目" label-width="120px" prop="projectCode">
                <el-select
                  v-model="itemDataForm.projectCode"
                  placeholder="请选择项目"
                  clearable
                >
                  <el-option
                    v-for="item in projectInfoList"
                    :key="item.projectCode"
                    :label="item.projectName"
                    :value="item.projectCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="巡检项名" label-width="120px" prop="itemName">
                <el-input
                  autocomplete="off"
                  v-model="itemDataForm.itemName"
                  clearable
                  placeholder="最多输入50个字"
                ></el-input>
              </el-form-item>
              <el-row :gutter="5">
                <el-col :span="11">
                  <el-form-item label="经度" label-width="120px" prop="itemLng">
                    <el-input
                      autocomplete="off"
                      v-model="itemDataForm.itemLng"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="纬度" label-width="60px" prop="itemLat">
                    <el-input
                      autocomplete="off"
                      v-model="itemDataForm.itemLat"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-button @click="editLngLatShowDialog" type="primary" class="el-icon-location-information">选择坐标</el-button>
                </el-col>
              </el-row>
              <el-form-item label="巡检内容" label-width="120px" prop="checkContent">
                <el-input
                  type="textarea"
                  v-model="itemDataForm.checkContent"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="editItemDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="editItemInfoSubmit()">提 交</el-button>
            </span>
          </el-dialog>

          <el-dialog
            title="选择坐标"
            :visible.sync="showMapDialogVisible"
            v-if="showMapDialogVisible"
            width="60%">
            <v-coordinator :defaultPoit="markerPoit" :defaultAddress="mapAddress" :canEdit="mapCanEdit" @onLocation="mapLocation"></v-coordinator>
            <span slot="footer" class="dialog-footer">
              <el-button @click="showMapDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="showMapDialogVisible = false">确 定</el-button>
            </span>
          </el-dialog>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import vCoordinator from "@/components/coordinatSelector/Coordinator.vue";
  export default {
    components: {
      vCoordinator
    },
    data() {
      return {
        addDialogVisible: false,
        editDialogVisible: false,
        addItemDialogVisible: false,
        editItemDialogVisible: false,
        tableInfoList: [],
        queryParams: {
          projectCode: "",
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        queryItemParams: {
          typeCode: "",
          pageNo: 1,
          pageSize: 10,
        },
        totalItem: 0,
        formRules: {
          projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
          checkTypeName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        },
        dataFormObj: {},
        projectInfoList: [],
        currentSelectRow: {},
        tableHeight:0,
        checkItemList: [],
        itemDataForm: {},
        itemFormRules: {
          projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
          itemName: [{ required: true, message: "请输入名称", trigger: "blur" }],
          checkContent: [{ required: true, message: "请输入巡检内容", trigger: "blur" }],
          itemLng: [{ required: true, message: "请选择坐标", trigger: "blur" }],
          itemLat: [{ required: true, message: "请选择坐标", trigger: "blur" }],
        },
        showMapDialogVisible: false,
        mapCanEdit: true,
        mapAddress: "",
        markerPoit: {
          lng: "116.404",
          lat: "39.915",
        },
        // 是否新增方式打开地图
        showMapIsAdd: true,
      }
    },
    created() {
      this.getAllProject();
    },
    mounted(){
      this.tableHeight = this.$refs.tableBox.clientHeight - 40;
    },
    methods: {
      async getAllProject() {
        const { data: res } = await this.$http.get("project/find/all");
        if (res.code == 0) {
          this.projectInfoList = res.data;
          if (this.projectInfoList != null) {
          this.queryParams.projectCode = this.projectInfoList[0].projectCode;
        }
        this.getTableDataList();
        } else {
          this.$message.error(res.msg);
        }
      },
      handleSizeChange(newSize) {
        this.queryParams.pageSize = newSize;
        this.getTableDataList();
      },
      handleCurrentChange(newPage) {
        this.queryParams.pageNo = newPage;
        this.getTableDataList();
      },
      handleItemSizeChange(newSize) {
        this.queryItemParams.pageSize = newSize;
        this.getCheckItemList();
      },
      handleItemCurrentChange(newPage) {
        this.queryItemParams.pageNo = newPage;
        this.getCheckItemList();
      },
      projectCodeChange(projectCode) {
        this.queryParams.projectCode = projectCode;
        this.getTableDataList();
      },
      async getTableDataList() {
        const { data: res } = await this.$http.get("pro/check/type/find/byPage", {
          params: this.queryParams
        });
        if (res.code == 0) {
          if (res.data == null) {
            this.tableInfoList = [];
            return;
          }
          this.tableInfoList = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      },
      showAddDialog() {
        this.dataFormObj = {};
        this.addDialogVisible = true;
      },
      addDialogClosed() {
        this.$refs.addInfoRef.resetFields();
      },
      showAddItemDialog() {
        this.itemDataForm = {};
        this.addItemDialogVisible = true;
      },
      addItemDialogClosed() {
        this.$refs.addItemInfoRef.resetFields();
      },
      async addInfoSubmit() {
        this.dataFormObj.projectCode = this.queryParams.projectCode;
        this.$refs.addInfoRef.validate(async (valid) => {
          if (valid) {
            const { data: res } = await this.$http.post(
              "pro/check/type/add",
              this.dataFormObj
            );
            if (res.code == 0) {
              this.$message.success("添加成功");
              this.getTableDataList();
            } else {
              this.$message.error(res.msg);
              return false;
            }
            this.addDialogVisible = false;
          } else {
            return false;
          }
        });
      },
      async showEditDialog(id) {
        this.dataFormObj = {};
        const { data: res } = await this.$http.get("pro/check/type/find/one/" + id);
        if (res.code == 0) {
          this.dataFormObj = res.data;
          this.editDialogVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      },
      async editInfoSubmit() {
        this.$refs.editInfoRef.validate(async (valid) => {
          if (valid) {
            const { data: res } = await this.$http.post(
              "pro/check/type/edit",
              this.dataFormObj
            );
            if (res.code == 0) {
              this.$message.success("修改成功");
              this.getTableDataList();
            } else {
              this.$message.error(res.msg);
              return false;
            }
            this.editDialogVisible = false;
          } else {
            return false;
          }
        });
      },
      async removeById(id) {
        const confirmResult = await this.$confirm("是否确认删除该类型吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        // 点击确定 返回值为：confirm
        // 点击取消 返回值为： cancel
        if (confirmResult !== "confirm") {
          return this.$message.info("已取消删除");
        }
        const { data: res } = await this.$http.delete("pro/check/type/remove/" + id);
        if (res.code == 0) {
          this.$message.success("删除成功！");
          this.getTableDataList();
        } else {
          return this.$message.error(res.msg);
        }
      },
      selectDzRow(row, column, event) {
        this.currentSelectRow = row;
        this.getCheckItemList();
      },
      async getCheckItemList() {
        this.queryItemParams.typeCode = this.currentSelectRow.checkTypeCode;
        const { data: res } = await this.$http.get("pro/check/item/find/byPage", {
          params: this.queryItemParams
        });
        if (res.code == 0) {
          if (res.data == null) {
            this.checkItemList = [];
            return;
          }
          this.checkItemList = res.data.list;
          this.totalItem = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      },
      async addItemInfoSubmit() {
        this.itemDataForm.projectCode = this.queryParams.projectCode;
        this.itemDataForm.checkTypeCode = this.currentSelectRow.checkTypeCode;
        this.$refs.addItemInfoRef.validate(async (valid) => {
          if (valid) {
            const { data: res } = await this.$http.post(
              "pro/check/item/add",
              this.itemDataForm
            );
            if (res.code == 0) {
              this.$message.success("添加成功");
              this.getCheckItemList();
            } else {
              this.$message.error(res.msg);
              return false;
            }
            this.addItemDialogVisible = false;
          } else {
            return false;
          }
        });
      },
      async showItemEditDialog(id) {
        this.itemDataForm = {};
        const { data: res } = await this.$http.get("pro/check/item/find/one/" + id);
        if (res.code == 0) {
          this.itemDataForm = res.data;
          this.editItemDialogVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      },
      async editItemInfoSubmit() {
        this.$refs.editItemInfoRef.validate(async (valid) => {
          if (valid) {
            const { data: res } = await this.$http.post(
              "pro/check/item/edit",
              this.itemDataForm
            );
            if (res.code == 0) {
              this.$message.success("修改成功");
              this.getCheckItemList();
            } else {
              this.$message.error(res.msg);
              return false;
            }
            this.editItemDialogVisible = false;
          } else {
            return false;
          }
        });
      },
      async removeItemById(id) {
        const confirmResult = await this.$confirm("是否确认删除该巡检项吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        // 点击确定 返回值为：confirm
        // 点击取消 返回值为： cancel
        if (confirmResult !== "confirm") {
          return this.$message.info("已取消删除");
        }
        const { data: res } = await this.$http.delete("pro/check/item/remove/" + id);
        if (res.code == 0) {
          this.$message.success("删除成功！");
          this.getCheckItemList();
        } else {
          return this.$message.error(res.msg);
        }
      },
      addLngLatShowDialog() {
        var p = this.getProject(this.queryParams.projectCode);
        this.mapCanEdit = true;
        this.showMapIsAdd = true;
        this.markerPoit = {lng:p.lng, lat:p.lat};
        this.showMapDialogVisible = true;
      },
      editLngLatShowDialog() {
        this.mapCanEdit = true;
        this.showMapIsAdd = false;
        this.markerPoit = {lng:this.itemDataForm.itemLng, lat:this.itemDataForm.itemLat};
        this.showMapDialogVisible = true;
      },
      mapLocation(data) {
        if (this.showMapIsAdd) {
          this.itemDataForm.itemLng = '' + data.point.lng;
          this.itemDataForm.itemLat = '' + data.point.lat;
        } else {
          this.itemDataForm.itemLng = '' + data.point.lng;
          this.itemDataForm.itemLat = '' + data.point.lat;
        }
      },
      getProject(code) {
        var pro = null;
        this.projectInfoList.forEach(p => {
          if (code === p.projectCode) {
            pro = p;
          }
        });
        return pro;
      }
    }
  }
  </script>
  
<style lang="less" scoped>
    .dataTable {
      width: 100%;
      height: calc(100vh - 270px);
      position: relative;
    }
  
    .select-gnss {
      height: calc(100vh - 270px);
      position: relative;
    }
  </style>