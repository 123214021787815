<template>
  <div class="header">
    <!-- 折叠按钮 -->
    <div class="collapse-btn" @click="collapseChage">
      <i v-if="!collapse" class="el-icon-s-fold"></i>
      <i v-else class="el-icon-s-unfold"></i>
    </div>
    <div class="logo">智能巡检平台</div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- 用户头像 -->
        <div class="user-avator">
          <img v-if="userGender === '1'" src="../../assets/img/boy.jpg" />
          <img v-else-if="userGender === '0'" src="../../assets/img/girl.jpg" />
        </div>
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ username }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item divided command="userInfo"
              >个人信息</el-dropdown-item
            >
            <el-dropdown-item divided command="changePwd"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item divided command="loginout"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!-- 个人信息对话框 -->
    <el-dialog
      title="个人信息"
      :visible.sync="userInfoDialog"
      width="50%"
      @close="setUserInfoDialogClosed"
    >
      <el-form :model="userInfoForm" :rules="rules" ref="userInfoRef">
        <el-row>
          <el-col :span="11">
            <el-form-item label="账号" :label-width="formLabelWidth">
              <el-input
                autocomplete="off"
                v-model="userInfoForm.accountDTO.account"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2"> </el-col>
          <el-col :span="11">
            <el-form-item label="账号类型" :label-width="formLabelWidth">
              <el-radio-group
                v-model="userInfoForm.accountDTO.accountType"
                :disabled="true"
              >
                <el-radio :label="1">普通用户</el-radio>
                <el-radio :label="2">管理员</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item
              prop="userDTO.realName"
              label="姓名"
              :label-width="formLabelWidth"
            >
              <el-input
                autocomplete="off"
                v-model="userInfoForm.userDTO.realName"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="11">
            <el-form-item
              prop="userDTO.gender"
              label="性别"
              :label-width="formLabelWidth"
            >
              <el-radio-group v-model="userInfoForm.userDTO.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item
              prop="accountDTO.phone"
              label="手机号"
              :label-width="formLabelWidth"
            >
              <el-input
                autocomplete="off"
                v-model="userInfoForm.accountDTO.phone"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="11">
            <el-form-item
              prop="accountDTO.email"
              label="邮箱"
              :label-width="formLabelWidth"
            >
              <el-input
                autocomplete="off"
                v-model="userInfoForm.accountDTO.email"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <v-addressSelector
          @addressChange="chengeAddress"
          :selectedProvinceCode="selectedProvinceCode"
          :selectedProvinceName="selectedProvinceName"
          :selectedCityCode="selectedCityCode"
          :selectedCityName="selectedCityName"
          :selectedAreaCode="selectedAreaCode"
          :selectedAreaName="selectedAreaName"
        >
        </v-addressSelector>
        <el-form-item
          prop="userDTO.address"
          label="详细地址"
          :label-width="formLabelWidth"
        >
          <el-input
            autocomplete="off"
            v-model="userInfoForm.userDTO.address"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="创建时间" :label-width="formLabelWidth">
          <el-input
            autocomplete="off"
            v-model="userInfoForm.accountDTO.createTime"
            :disabled="true"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="userInfoDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">保 存</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码对话框 -->
    <el-dialog
      title="修改密码"
      :visible.sync="changePwdDialog"
      width="50%"
      @close="setPwdDialogClosed"
    >
      <el-form :model="changePwdForm" :rules="rulesPwd" ref="changePwdRef">
        <el-form-item
          label="旧密码"
          :label-width="formLabelWidth"
          prop="oldPassword"
        >
          <el-input
            type="password"
            autocomplete="off"
            v-model="changePwdForm.oldPassword"
            clearable
            placeholder="用户密码的长度在6～18个字"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="新密码"
          :label-width="formLabelWidth"
          prop="newPassword"
        >
          <el-input
            type="password"
            autocomplete="off"
            v-model="changePwdForm.newPassword"
            clearable
            placeholder="用户密码的长度在6～18个字"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认新密码"
          :label-width="formLabelWidth"
          prop="comfPassword"
        >
          <el-input
            type="password"
            autocomplete="off"
            v-model="changePwdForm.comfPassword"
            clearable
            placeholder="用户密码的长度在6～18个字"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changePwdDialog = false">取 消</el-button>
        <el-button type="primary" @click="changeMyPwd()">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "../common/bus";
import vAddressSelector from '../addressSelector/AddressSelector.vue';
export default {
  components: {
    vAddressSelector
  },
  data () {
    // 自定义邮箱规则
    var checkEmail = (rule, value, callback) => {
      if (value.length == 0) {
        return callback()
      }
      const regEmail = /^\w+@\w+(\.\w+)+$/
      if (regEmail.test(value)) {
        // 合法邮箱
        return callback()
      }
      callback(new Error('请输入合法邮箱'))
    }
    var checkEmailExist = async (rule, value, callback) => {
      if (value.length == 0) {
        return callback()
      }
      // 验证平台是否已存在该邮箱
      const { data: res } = await this.$http.get('private/email/exist/personal?email=' + value);
      if (res.code == 0) {
        // 未被使用时返回false
        if (res.data == 0) {
          return callback()
        }
      }

      callback(new Error('邮箱已经被使用'))
    }
    // 自定义手机号规则
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/
      if (regMobile.test(value)) {
        return callback()
      }
      // 返回一个错误提示
      callback(new Error('请输入合法的手机号码'))
    }
    var checkMobileExist = async (rule, value, callback) => {
      const { data: res } = await this.$http.get('private/phone/exist/personal?phone=' + value);
      if (res.code == 0) {
        // 未被使用时返回false
        if (res.data == 0) {
          return callback()
        }
      }

      // 返回一个错误提示
      callback(new Error('手机号码已经被使用'))
    }

    // 验证确认密码是否一致
    var confirmPwd = (rule, value, callback) => {
      if (this.changePwdForm.newPassword == value) {
        return callback()
      }
      callback(new Error('两次输入的密码不一致'))
    }

    return {
      selectedProvinceCode: '',
      selectedProvinceName: '',
      selectedCityCode: '',
      selectedCityName: '',
      selectedAreaCode: '',
      selectedAreaName: '',
      rules: {
        accountDTO: {
          phone: [{ required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkMobile, trigger: 'blur' },
          { validator: checkMobileExist, trigger: 'blur' }],
          email: [{ validator: checkEmail, trigger: 'blur' },
          { validator: checkEmailExist, trigger: 'blur' }]
        },
        userDTO: {
          realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
          provinceCode: [{ required: true, message: "请选择省份", trigger: "blur" }],
          cityCode: [{ required: true, message: "请选择城市", trigger: "blur" }],
          // areaCode: [{ required: true, message: "请选择区", trigger: "blur" }],
          // address: [
          //   { required: true, message: "请输入详细地址", trigger: "blur" },
          // ]
        }
      },
      rulesPwd: {
        oldPassword: [{ required: true, message: "请输入旧密码", trigger: "blur" },
        {
          min: 6,
          max: 18,
          message: '用户密码的长度在6～18个字',
          trigger: 'blur'
        }],
        newPassword: [{ required: true, message: "请输入新密码", trigger: "blur" },
        {
          min: 6,
          max: 18,
          message: '用户密码的长度在6～18个字',
          trigger: 'blur'
        }],
        comfPassword: [{ required: true, message: "请确认密码", trigger: "blur" },
        {
          min: 6,
          max: 18,
          message: '用户密码的长度在6～18个字',
          trigger: 'blur'
        },
        { validator: confirmPwd, trigger: 'blur' }]
      },
      userInfoForm: {
        accountDTO: {
          account: "",
          email: "",
          phone: "",
          accountType: 1,
          createTime: ""
        },
        userDTO: {
          realName: "",
          gender: 0,
          address: "",
          provinceName: "",
          provinceCode: "",
          cityName: "",
          cityCode: "",
          areaName: "",
          areaCode: ""
        }
      },
      changePwdForm: {
        oldPassword: "",
        newPassword: "",
        comfPassword: ""
      },
      collapse: false,
      fullscreen: false,
      name: "",
      message: 0,
      userInfoDialog: false,
      changePwdDialog: false,
      formLabelWidth: '120px',
      provinceList: [],
      cityList: [],
      areaList: [],
      currentProjectName: "所有项目",
      userGender: '1',
    };
  },
  destroyed () {
    bus.$off('total-msg-event');
  },
  created () {
    // this.getMsgCount();
    this.loadLoginUserContext();
    this.getGender();

    bus.$on("total-msg-event", (msg) => {
      // console.log('总数消息');
      // console.log(msg);
      this.message = msg.data;
    });
  },
  computed: {
    username () {
      let username = localStorage.getItem("realName");
      return username ? username : this.name;
    }
  },
  methods: {
    // async getMsgCount () {
    //   const { data: res } = await this.$http.get('message/unread/msg/count');
    //   if (res.code == 0) {
    //     this.message = res.data;
    //   }
    // },
    getGender () {
      this.userGender = localStorage.getItem("gender");
      // console.log("gender" + this.userGender);
    },
    // 用户名下拉菜单选择事件
    async handleCommand (command) {
      if (command == "loginout") {
        const { data: res } = await this.$http.get('logout');
        if (res.code == 0) {
          localStorage.removeItem("token");
          localStorage.removeItem("ms_username");
          localStorage.removeItem("realName");
          localStorage.removeItem("user_perms");
          localStorage.removeItem("gender");
          this.$router.push("/login");
        } else {
          this.$message.error(res.msg);
        }
      } else if (command == "userInfo") {
        const { data: res } = await this.$http.get('private/personal/info');
        if (res.code == 0) {
          console.log(res.data);
          this.userInfoForm.accountDTO = res.data.accountDTO;
          this.userInfoForm.userDTO = res.data.userDTO;
          this.selectedProvinceCode = res.data.userDTO.provinceCode;
          this.selectedProvinceName = res.data.userDTO.provinceName;
          this.selectedCityCode = res.data.userDTO.cityCode;
          this.selectedCityName = res.data.userDTO.cityName;
          this.selectedAreaCode = res.data.userDTO.areaCode;
          this.selectedAreaName = res.data.userDTO.areaName;
          this.userInfoDialog = true;
        } else {
          this.$message.error(res.msg);
        }
      } else if (command == "changePwd") {
        this.changePwdForm.oldPassword = "";
        this.changePwdForm.newPassword = "";
        this.changePwdForm.comfPassword = "";
        this.changePwdDialog = true;
      }
    },
    // 侧边栏折叠
    collapseChage () {
      this.collapse = !this.collapse;
      bus.$emit("collapse", this.collapse);
    },
    // 全屏事件
    handleFullScreen () {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    submitForm () {
      this.$refs.userInfoRef.validate(async (valid) => {
        if (valid) {
          this.userInfoForm.userDTO.provinceCode = this.selectedProvinceCode;
          this.userInfoForm.userDTO.provinceName = this.selectedProvinceName;
          this.userInfoForm.userDTO.cityCode = this.selectedCityCode;
          this.userInfoForm.userDTO.cityName = this.selectedCityName;
          this.userInfoForm.userDTO.areaCode = this.selectedAreaCode;
          this.userInfoForm.userDTO.areaName = this.selectedAreaName;
          const { data: res } = await this.$http.post("private/update/personal", this.userInfoForm);
          if (res.code == 0) {
            this.$message.success("修改成功");
          } else {
            this.$message.error(res.msg);
            return false;
          }
        } else {
          return false;
        }
      });
    },
    changeMyPwd () {
      this.$refs.changePwdRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('private/update/personal/pwd', this.changePwdForm);
          if (res.code == 0) {
            console.log(res.data);
            this.$message.success("修改成功");
            this.$confirm('系统将自动跳转到登录页面').then(() => {
              this.handleCommand('loginout');
            }).catch(() => {
              this.handleCommand('loginout');
            })
          } else {
            this.$message.error(res.msg);
          }
        } else {
          return false;
        }
      });
    },

    setPwdDialogClosed () {
      this.$refs.changePwdRef.resetFields();
    },

    setUserInfoDialogClosed () {
      this.$refs.userInfoRef.resetFields();
    },

    async loadLoginUserContext () {
      const { data: res } = await this.$http.get('private/session/ctx');
      if (res.code == 0) {
        this.currentProjectName = res.data.projectName == null ? '所有项目' : res.data.projectName;
      }
    },
    chengeAddress (data) {
      this.selectedProvinceCode = data.provinceCode;
      this.selectedProvinceName = data.provinceName;
      this.selectedCityCode = data.cityCode;
      this.selectedCityName = data.cityName;
      this.selectedAreaCode = data.areaCode;
      this.selectedAreaName = data.areaName;
    }
  },
  mounted () {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  },
};
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;
}
.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
}
.header .logo {
  float: left;
  width: 250px;
  line-height: 70px;
}
.header-right {
  float: right;
  padding-right: 50px;
}
.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.btn-bell .el-icon-bell {
  color: #fff;
}
.user-name {
  margin-left: 10px;
}
.user-avator {
  margin-left: 20px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}
.el-dropdown-menu__item {
  text-align: center;
}
.project-user-info {
  height: 30px;
  width: 160px;
  text-align: right;
  margin-right: 10px;
  overflow: hidden;
}
.project-text {
  width: 100px;
  height: 30px;
  font-size: 16px;
  margin-left: 4px;
  color: #fff;
}
</style>
