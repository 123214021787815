<template>
  <div style="height:100%;width:100%">
    <baidu-map ak=COMMON_CONFIG.BAIDU_MAP_AK :scroll-wheel-zoom="true" @ready="mapReady"
      :center="center" :zoom="zoom" style="height:calc(100%);">
      <!--地图视图-->
      <bm-view class="map" ref="mapRootRef"></bm-view>
      <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-scale>
    </baidu-map>
  </div>
</template>

<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmView from "vue-baidu-map/components/map/MapView"; //地图视图
import BmMarker from "vue-baidu-map/components/overlays/Marker"; //点标注
import BmNavigation from "vue-baidu-map/components/controls/Navigation"; //点标注
import BmMapType from "vue-baidu-map/components/controls/MapType"; //点标注
import BmScale from "vue-baidu-map/components/controls/Scale"; //点标注
import checkMarker from "@/assets/img/marker/check-marker.png";
import normalMarker from "@/assets/img/marker/normal-marker.png";
import { off } from "process";
export default {
  components: {
    BaiduMap,
    BmView,
    BmMarker,
    BmNavigation,
    BmMapType,
    BmScale
  },
  props: {
    canEdit: Boolean,
    data: Object
  },
  inheritAttrs: false,
  data() {
    return {
      center: {
        //经纬度
        lng: 116.417,
        lat: 39.909,
      },
      zoom: 17, //地图展示级别
      dbMap: null,
      checkNormalItems: [],
      currentNormalItemsMarker: [],
      currentCheckLogMarker: null,
      checkMarkerIcon: {
        checkMarker: checkMarker,
        normalMarker: normalMarker
      },
      projectInfo: {},
      lineInMap: null,
    }
  },
  created() {

  },
  methods: {
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        if (res.data != null) {
          this.projectInfo = res.data[0];
          this.changeCenter(this.projectInfo);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    mapReady({ BMap, map }) {
      map.setMapType(BMAP_HYBRID_MAP);
      this.dbMap = map;
      this.getAllProject();
    },
    changeCenter(projectInfo) {
      if (projectInfo == null) {
        return;
      }

      this.dbMap.panTo(new BMap.Point(projectInfo.lng, projectInfo.lat));
      if (this.data.lineCode !== undefined) {
        console.log(this.data.lineCode)
        this.getLineItemList(this.data.lineCode);
      }
    },
    async getLineItemList(lineCode) {
      const { data: res } = await this.$http.get("pro/check/item/find/byline", {
        params: {
          pageNo: 1,
          pageSize: 900,
          lineCode: lineCode
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.checkNormalItems = [];
          return;
        }
        this.checkNormalItems = res.data.list;
        this.createNormalItemMarker();
        this.loadCheckLine(this.data.id);
      }
    },
    createNormalItemMarker() {
      this.clearOldNormalMarker();
      this.checkNormalItems.forEach(i => {
        var icon = new BMap.Icon(this.checkMarkerIcon.normalMarker, new BMap.Size(20, 26));
        var point = new BMap.Point(i.itemLng, i.itemLat);
        var marker = new BMap.Marker(point);
        marker.setIcon(icon);
        var lb = new BMap.Label("【参考点】" + i.itemName);
        lb.setOffset(new BMap.Size(18, 0));
        lb.setStyle({ color : "black", fontSize : "10px", border: 0, background: "rgba(255,255,255,0.8)"});
        marker.setLabel(lb);
        this.dbMap.addOverlay(marker);
        this.currentNormalItemsMarker.push(marker);
      });
    },
    clearOldNormalMarker() {
      this.currentNormalItemsMarker.forEach(m => {
        this.dbMap.removeOverlay(m);
      });
      this.currentNormalItemsMarker = [];
    },
    createCheckLogMarker(record) {
      let that = this;
      this.clearOldCheckLogMarker();
      var icon = new BMap.Icon(this.checkMarkerIcon.checkMarker, new BMap.Size(20, 26));
      var point = new BMap.Point(record.checkLng, record.checkLat);
      var marker = new BMap.Marker(point);
      marker.setIcon(icon);
      var lb = new BMap.Label("【已巡检】" + record.checkItemName + "<br/>" + record.checkTime);
      lb.setOffset(new BMap.Size(18, -30));
      lb.setStyle({ color: "black", fontSize: "10px", border: 0, background: "rgba(255,255,255,0.9)" });
      marker.setLabel(lb);
      marker.addEventListener('click', function () {
        that.showLogDetail(record);
      });
      this.dbMap.addOverlay(marker);
      this.currentCheckLogMarker = marker;
    },
    clearOldCheckLogMarker() {
      if (this.currentCheckLogMarker) {
        this.dbMap.removeOverlay(this.currentCheckLogMarker);
      }
    },
    movetocenter(data) {
      this.dbMap.panTo(new BMap.Point(data.checkLng, data.checkLat));
      this.createCheckLogMarker(data);
    },
    showLogDetail(data) {
      this.$emit("onShowLogDetail", data);
    },
    async loadCheckLine(lineId) {
      var line = [];
      this.checkNormalItems.forEach(e => {
        line.push(e);
      });

      const { data: res } = await this.$http.get("pro/check/line/find/one/" + lineId);
      if (res.code == 0) {
        this.arraySort(line);
        this.createLine(line, res.data);
      }
    },
    createLine(points, lineData) {
      var ps = [];
      // console.log(lineData);
      if (lineData.linePointCt !== null && lineData.linePointCt !== '') {
        var jsonArray = JSON.parse(lineData.linePointCt);
        jsonArray.forEach(o => {
          ps.push(new BMap.Point(o.lng, o.lat));
        });
        // console.log(JSON.parse(this.data.linePointCt));
      } else {
        points.forEach(p => {
          ps.push(new BMap.Point(p.itemLng, p.itemLat));
        });
      }
      var polyline = new BMap.Polyline(ps, {
          strokeColor: lineData.lineColor,
          strokeWeight: 4
      });
      if (this.canEdit) {
        polyline.enableEditing();
      } else { 
        polyline.disableEditing();
      }
      this.dbMap.addOverlay(polyline);
      this.lineInMap = polyline;
    },
    arraySort(list) {
      for (let i = 0; i < list.length - 1; i++) {
        for (let j = 0; j < list.length - 1 - i; j++) {
          if (list[j].lineSort > list[j+1].lineSort) {
            var temp = list[j];
            list[j] = list[j+1];
            list[j+1] = temp;
          }
        }
      }
    },
    clearLine() {
      if (this.lineInMap) {
        this.dbMap.removeOverlay(this.lineInMap);
      }
    },
    async saveLinePoint() {
      if (this.lineInMap === null) {
        return;
      }
      // console.log(this.lineInMap.getPath());
      var updateObject = {
        id: this.data.id,
        linePointCt: JSON.stringify(this.lineInMap.getPath())
      };
      const { data: res } = await this.$http.post(
        "pro/check/line/edit",
        updateObject
      );
      if (res.code == 0) {
        this.$message.success("保存成功");
      } else {
        this.$message.error(res.msg);
        return false;
      }
    },
    async resetLineInfo() {
      this.clearLine();
      var line = [];
      this.checkNormalItems.forEach(e => {
        line.push(e);
      });

      const { data: res } = await this.$http.get("pro/check/line/find/one/" + this.data.id);
      if (res.code == 0) {
        this.arraySort(line);
        res.data.linePointCt = '';
        this.createLine(line, res.data);
      }
    }
  }
}
</script>

<style>
.map {
  width: 100%;
  height:calc(100%);
}
</style>