<template>
  <div>
    <el-card>
      <el-row :gutter="10">
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          @click="getUserList"
          >刷新</el-button
        >
        <el-button type="success" icon="el-icon-download" @click="updateFromWx"
          >从微信更新人员</el-button
        >
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="userInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column
          label="昵称"
          prop="nickname"
          width="140px"
        ></el-table-column>
        <!-- <el-table-column
          label="openid"
          width="300px"
          prop="openid"
        ></el-table-column> -->
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="别名" prop="aliasname"></el-table-column>
        <el-table-column label="性别" width="80px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.sex === 0">男</el-tag>
            <el-tag v-else-if="scope.row.sex === 1" type="danger">女</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="createTime"
          width="160px"
        ></el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
              v-if="bindUser == false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <el-button
              v-if="bindUser && !scope.row.bindUser"
              type="success"
              size="mini"
              @click="handleBind(scope.row.openid)"
              >绑定</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 修改用户信息 -->
    <el-dialog title="修改用户信息" :visible.sync="showEditUser" width="30%">
      <el-form
        :model="editUserInfoForm"
        :rules="editUserRules"
        ref="editUserInfoRef"
        label-width="80px"
      >
        <el-form-item label="别名" prop="aliasname">
          <el-input
            autocomplete="off"
            v-model="editUserInfoForm.aliasname"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditUser = false">取 消</el-button>
        <el-button type="primary" @click="userInfoSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    bindUser: Boolean
  },
  inheritAttrs: false,
  data () {
    return {
      editUserRules: {
        aliasname: [{ required: true, message: "请输入别名", trigger: "blur" }],
      },
      showEditUser: false,
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        projectCode: null,
        bindUser: false
      },
      total: 0,
      userInfoList: [],
      editUserInfoForm: {
        id: null,
        aliasname: null
      }
    }
  },
  created () {
    this.getUserList();
    // console.log(this.projectCode);
  },
  methods: {
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getUserList();
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getUserList();
    },
    async getUserList () {
      this.queryParams.bindUser = this.bindUser;
      const { data: res } = await this.$http.get("wx/find/user/byPage", {
        params: this.queryParams,
      });
      if (res.code == 0) {
        this.userInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    showEditDialog (id) {
      this.editUserInfoForm = {};
      this.showEditUser = true;
      this.editUserInfoForm.id = id;
    },
    async userInfoSubmit () {
      this.$refs.editUserInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "wx/update/user",
            this.editUserInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getUserList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.showEditUser = false;
        } else {
          return false;
        }
      });
    },
    async updateFromWx () {
      const confirmResult = await this.$confirm(
        "将从微信全量更新关注用户列表，是否继续？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        return false;
      }

      const { data: res } = await this.$http.get("wx/force/update");
      if (res.code == 0) {
        this.$message.success("更新成功");
        this.getUserList();
      } else {
        this.$message.error(res.msg);
      }
    },
    handleBind (data) {
      this.$emit("pjbind", data);
    },
    refreshData () {
      this.getUserList();
    }
  }
}
</script>