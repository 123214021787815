<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>风险类型管理</el-breadcrumb-item>
    </el-breadcrumb>


    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col>
          <el-button 
            type="primary" 
            @click="addInfoDialogVisible = true" 
            v-has="'sys:role:add'"
            icon="el-icon-plus"
            >新增</el-button
          >
        </el-col>
      </el-row>


      <!-- 表格数据 -->
      <el-table :data="dataList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="隐患名称" prop="dgName"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="160px"></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row.id)"
                v-has="'sys:role:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeById(scope.row.id)"
                v-has="'sys:role:delete'"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>


      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>


      <!-- 添加数据 -->
      <el-dialog
        title="添加隐患类型"
        :visible.sync="addInfoDialogVisible"
        width="40%"
        @close="addDialogClosed"
        :append-to-body="true"
      >
        <el-form :model="addInfoForm" :rules="addRules" ref="addDataRef">
          <el-form-item label="隐患类型名" label-width="120px" prop="dgName">
            <el-input
              autocomplete="off"
              v-model="addInfoForm.dgName"
              clearable
              placeholder="最多输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="addInfoForm.remark"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addInfoDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>


      <!-- 修改隐患类型 -->
      <el-dialog
        title="修改隐患类型"
        :visible.sync="editDialogVisible"
        width="40%"
        @close="editDialogClosed"
      >
        <el-form :model="editInfoForm" :rules="addRules" ref="editInfoRef">
          <el-form-item label="隐患类型名" label-width="120px" prop="dgName">
            <el-input
              autocomplete="off"
              v-model="editInfoForm.dgName"
              clearable
              placeholder="最多输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="editInfoForm.remark"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addRules: {
        dgName: [
          { required: true, message: "请输入类型名称", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "最多只能输入20个字",
            trigger: "blur",
          },
        ],
        remark: [
          {
            min: 2,
            max: 50,
            message: "最多只能输入50个字",
            trigger: "blur",
          },
        ]
      },
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      addInfoForm: {
        projectCode: "",
        dgName: "",
        remark: ""
      },
      editInfoForm: {

      },
      dataList: [],
      projectList: [],
      currentProjectInfo: {},
      addInfoDialogVisible: false,
      editDialogVisible: false
    }
  },
  created() {
    this.getProjectList();
  },
  methods: {
    async getProjectList() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectList = res.data;
        this.currentProjectInfo = res.data[0];
        this.getDangerTypeList();
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDangerTypeList() {
      this.queryParams.projectCode = this.currentProjectInfo.projectCode;
      const { data: res } = await this.$http.get("dtype/find/byPage", {
        params: this.queryParams,
      });
      if (res.code == 0) {
        this.dataList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    addDialogClosed() {
      this.$refs.addDataRef.resetFields();
    },
    editDialogClosed() {
      // this.$refs.editInfoRef.resetFields();
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getDangerTypeList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getDangerTypeList();
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("dtype/find/" + id);
      if (res.code == 0) {
        this.editInfoForm = res.data;
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    editInfoSubmit() {
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "dtype/update",
            this.editInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getDangerTypeList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("dtype/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getDangerTypeList();
      } else {
        return this.$message.error("删除失败！");
      }
    },
    addInfoSubmit() {
      this.addInfoForm.projectCode = this.currentProjectInfo.projectCode;
      this.$refs.addDataRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "dtype/add",
            this.addInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getDangerTypeList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addInfoDialogVisible = false;
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>

</style>