<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>项目列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="10">
        <el-col :span="3">
          <el-select v-model="queryParams.orgCode" placeholder="请选择组织" clearable @clear="getProjectList" @change="getProjectList">
                <el-option
                  v-for="item in orgInfoList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode">
                </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-input
            placeholder="请输入项目名称"
            v-model="queryParams.queryStr"
            clearable
            @clear="getProjectList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getProjectList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-button 
            type="primary" 
            @click="addProjectDialogShow()" 
            v-has="'sys:project:add'"
            icon="el-icon-plus"
            >添加项目</el-button
          >
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="projectInfoList" 
        ref="multipleTable"
        border stripe>
        <el-table-column label="项目名称" prop="projectName"></el-table-column>
        <el-table-column label="项目编号" prop="projectCode"></el-table-column>
        <el-table-column label="所属组织" prop="orgName"></el-table-column>
        <el-table-column label="省份" prop="provinceName"></el-table-column>
        <el-table-column label="城市" prop="cityName"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="160px"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="项目坐标"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-location-information"
                @click="showLocationDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.id)"
                v-has="'sys:project:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="removeProjectById(scope.row.id)"
                v-has="'sys:project:delete'"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>


      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>

      <!-- 添加项目 -->
      <el-dialog
        title="添加项目"
        :visible.sync="addProjectDialogVisible"
        width="40%"
        @close="addDialogClosed"
      >
        <el-form :model="addProjectInfoForm" :rules="addRules" ref="addProjectRef">
          <el-form-item label="所属组织" label-width="120px" prop="orgCode">
            <el-select v-model="addProjectInfoForm.orgCode" placeholder="请选择组织" clearable>
                <el-option
                  v-for="item in orgInfoList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目名称" label-width="120px" prop="projectName">
            <el-input
              autocomplete="off"
              v-model="addProjectInfoForm.projectName"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="项目规模" label-width="120px" prop="projectScale">
            <el-select v-model="addProjectInfoForm.projectScale" placeholder="请选择规模">
              <el-option label="小型" value="1"></el-option>
              <el-option label="中型" value="2"></el-option>
              <el-option label="大型" value="3"></el-option>
              <el-option label="超大型" value="4"></el-option>
            </el-select>
          </el-form-item> -->
          <v-addressSelector 
            ref="addAddressRef"
            @addressChange="chengeAddress"
            >
          </v-addressSelector>
          <el-row :gutter="5">
            <el-col :span="11">
              <el-form-item label="经度" label-width="120px" prop="lng">
                <el-input
                  autocomplete="off"
                  v-model="addProjectInfoForm.lng"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="纬度" label-width="60px" prop="lat">
                <el-input
                  autocomplete="off"
                  v-model="addProjectInfoForm.lat"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-button @click="addProjectLngLatShowDialog" type="primary" class="el-icon-location-information">选择坐标</el-button>
            </el-col>
          </el-row>
          <el-form-item label="海拔" label-width="120px" prop="elevation">
            <el-input
              autocomplete="off"
              v-model="addProjectInfoForm.elevation"
              clearable
              placeholder="单位：米"
            ></el-input>
          </el-form-item>
          <el-form-item label="滩顶高程" label-width="120px" prop="gaocheng">
            <el-input
              autocomplete="off"
              v-model="addProjectInfoForm.gaocheng"
              clearable
              placeholder="单位：米，如有高程设备则以监测值为准"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目地址" label-width="120px" prop="address">
            <el-input
              autocomplete="off"
              v-model="addProjectInfoForm.address"
              clearable
              placeholder="最多输入100个字"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="项目简介" label-width="120px" prop="introduction">
            <el-input type="textarea" v-model="addProjectInfoForm.introduction"></el-input>
          </el-form-item> -->
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="addProjectInfoForm.remark"
              clearable
              placeholder="最多输入200个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addProjectDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addProjectInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>


      <!-- 修改项目 -->
      <el-dialog
        title="修改项目"
        :visible.sync="editProjectDialogVisible"
        width="40%"
        @close="editDialogClosed"
      >
        <el-form :model="editProjectInfoForm" :rules="addRules" ref="editProjectRef">
          <el-form-item label="所属组织" label-width="120px" prop="orgCode">
            <el-select v-model="editProjectInfoForm.orgCode" placeholder="请选择组织" clearable>
                <el-option
                  v-for="item in orgInfoList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目名称" label-width="120px" prop="projectName">
            <el-input
              autocomplete="off"
              v-model="editProjectInfoForm.projectName"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="项目规模" label-width="120px" prop="projectScale">
            <el-select v-model="editProjectInfoForm.projectScale" placeholder="请选择规模">
              <el-option label="小型" :value="1"></el-option>
              <el-option label="中型" :value="2"></el-option>
              <el-option label="大型" :value="3"></el-option>
              <el-option label="超大型" :value="4"></el-option>
            </el-select>
          </el-form-item> -->
          <v-addressSelector 
            @addressChange="chengeAddress"
            :selectedProvinceCode="selectedProvinceCode"
            :selectedProvinceName="selectedProvinceName"
            :selectedCityCode="selectedCityCode"
            :selectedCityName="selectedCityName"
            :selectedAreaCode="selectedAreaCode"
            :selectedAreaName="selectedAreaName">
          </v-addressSelector>
          <el-row :gutter="5">
            <el-col :span="11">
              <el-form-item label="经度" label-width="120px" prop="lng">
                <el-input
                  autocomplete="off"
                  v-model="editProjectInfoForm.lng"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="纬度" label-width="60px" prop="lat">
                <el-input
                  autocomplete="off"
                  v-model="editProjectInfoForm.lat"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-button @click="editProjectLngLatShowDialog" type="primary" class="el-icon-location-information">选择坐标</el-button>
            </el-col>
          </el-row>
          <el-form-item label="海拔" label-width="120px" prop="elevation">
            <el-input
              autocomplete="off"
              v-model="editProjectInfoForm.elevation"
              clearable
              placeholder="单位：米"
            ></el-input>
          </el-form-item>
          <el-form-item label="滩顶高程" label-width="120px" prop="gaocheng">
            <el-input
              autocomplete="off"
              v-model="editProjectInfoForm.gaocheng"
              clearable
              placeholder="单位：米，如有高程设备则以监测值为准"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目地址" label-width="120px" prop="address">
            <el-input
              autocomplete="off"
              v-model="editProjectInfoForm.address"
              clearable
              placeholder="最多输入100个字"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="项目简介" label-width="120px" prop="introduction">
            <el-input type="textarea" v-model="editProjectInfoForm.introduction"></el-input>
          </el-form-item> -->
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="editProjectInfoForm.remark"
              clearable
              placeholder="最多输入200个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editProjectDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editProjectInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>


      <el-dialog
        title="选择坐标"
        :visible.sync="showMapDialogVisible"
        v-if="showMapDialogVisible"
        width="60%">
        <v-coordinator :defaultPoit="markerPoit" :defaultAddress="mapAddress" :canEdit="mapCanEdit" @onLocation="mapLocation"></v-coordinator>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showMapDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="showMapDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import vAddressSelector from '../../addressSelector/AddressSelector.vue';
import vCoordinator from "@/components/coordinatSelector/Coordinator.vue";
export default {
  components: {
    vAddressSelector,
    vCoordinator
  },
  data() {
    return {
      selectedProvinceCode: '',
      selectedProvinceName: '',
      selectedCityCode: '',
      selectedCityName: '',
      selectedAreaCode: '',
      selectedAreaName: '',
      queryParams: {
        queryStr: "",
        pageNo: 1,
        pageSize: 10,
        orgCode: null,
      },
      addProjectInfoForm: {
        orgCode: null,
        projectName: "",
        projectScale: null,
        provinceCode: null,
        provinceName: null,
        cityCode: null,
        cityName: null,
        areaCode: null,
        areaName: null,
        address: "",
        introduction: "",
        remark: "",
        lng: "",
        lat: "",
        elevation: "",
      },
      editProjectInfoForm: {},
      addRules: {
        lng: [
          { required: true, message: "请输入经度", trigger: "blur" }
        ],
        lat: [
          { required: true, message: "请输入纬度", trigger: "blur" }
        ],
        elevation: [
          { required: true, message: "请输入海拔", trigger: "blur" }
        ],
        orgCode: [
          { required: true, message: "请选择组织", trigger: "blur" }
        ],
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "最多只能输入50个字",
            trigger: "blur",
          },
        ],
        // projectScale: [
        //   { required: true, message: "请选择规模", trigger: "blur" }
        // ],
        address: [
          { required: true, message: "请输入地址", trigger: "blur" },
          {
            min: 2,
            max: 100,
            message: "最多只能输入100个字",
            trigger: "blur",
          },
        ],
        remark: [
          {
            min: 2,
            max: 200,
            message: "最多只能输入200个字",
            trigger: "blur",
          },
        ]
      },
      addProjectDialogVisible: false,
      editProjectDialogVisible: false,
      projectInfoList: [],
      total: 0,
      orgInfoList: [],
      showMapDialogVisible: false,
      mapCanEdit: true,
      markerPoit: {
        lng: "116.404",
        lat: "39.915",
      },
      mapAddress: "",
      // 是否新增方式打开地图
      showMapIsAdd: true,
    }
  },
  created() {
    this.getProjectList();
    this.getAllOrgList();
  },
  methods: {
    async getProjectList() {
      const { data: res } = await this.$http.get("project/find/byPage", {
        params: this.queryParams,
      });
      if (res.code == 0) {
        this.projectInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("project/find/" + id);
      if (res.code == 0) {
        this.editProjectInfoForm = res.data;
        this.selectedProvinceCode = res.data.provinceCode;
        this.selectedProvinceName = res.data.provinceName;
        this.selectedCityCode = res.data.cityCode;
        this.selectedCityName = res.data.cityName;
        this.selectedAreaCode = res.data.areaCode;
        this.selectedAreaName = res.data.areaName;
        this.editProjectDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeProjectById(id) {
      const confirmResult = await this.$confirm("是否确认删除该项目?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("project/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除项目成功！");
        this.getProjectList();
      } else {
        return this.$message.error("删除项目失败！");
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getProjectList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getProjectList();
    },
    async getAllOrgList() {
      const { data: res } = await this.$http.get("org/find/all");
      if (res.code == 0) {
        this.orgInfoList = res.data;
      }
    },
    addDialogClosed() {
      this.$refs.addAddressRef.clearData();
      this.$refs.addProjectRef.resetFields();
    },
    editDialogClosed() {
      // this.$refs.editProjectRef.resetFields();
    },
    addProjectDialogShow() {
      this.addProjectDialogVisible = true;
    },
    addProjectInfoSubmit() {
      this.$refs.addProjectRef.validate(async (valid) => {
        if (valid) {
          this.addProjectInfoForm.provinceCode = this.selectedProvinceCode;
          this.addProjectInfoForm.provinceName = this.selectedProvinceName;
          this.addProjectInfoForm.cityCode = this.selectedCityCode;
          this.addProjectInfoForm.cityName = this.selectedCityName;
          this.addProjectInfoForm.areaCode = this.selectedAreaCode;
          this.addProjectInfoForm.areaName = this.selectedAreaName;
          const { data: res } = await this.$http.post(
            "project/add",
            this.addProjectInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getProjectList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addProjectDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    editProjectInfoSubmit() {
      this.$refs.editProjectRef.validate(async (valid) => {
        if (valid) {
          this.editProjectInfoForm.provinceCode = this.selectedProvinceCode;
          this.editProjectInfoForm.provinceName = this.selectedProvinceName;
          this.editProjectInfoForm.cityCode = this.selectedCityCode;
          this.editProjectInfoForm.cityName = this.selectedCityName;
          this.editProjectInfoForm.areaCode = this.selectedAreaCode;
          this.editProjectInfoForm.areaName = this.selectedAreaName;
          const { data: res } = await this.$http.post(
            "project/update",
            this.editProjectInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getProjectList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editProjectDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    chengeAddress(data) {
      this.selectedProvinceCode = data.provinceCode;
      this.selectedProvinceName = data.provinceName;
      this.selectedCityCode = data.cityCode;
      this.selectedCityName = data.cityName;
      this.selectedAreaCode = data.areaCode;
      this.selectedAreaName = data.areaName;
      console.log(data);
    },
    addProjectLngLatShowDialog() {
      this.mapCanEdit = true;
      this.showMapIsAdd = true;
      this.markerPoit = {lng:"116.404", lat:"39.915"};
      this.showMapDialogVisible = true;
    },
    editProjectLngLatShowDialog() {
      this.mapCanEdit = true;
      this.showMapIsAdd = false;
      this.markerPoit = {lng:this.editProjectInfoForm.lng, lat:this.editProjectInfoForm.lat};
      this.mapAddress = this.editProjectInfoForm.address;
      this.showMapDialogVisible = true;
    },
    mapLocation(data) {
      if (this.showMapIsAdd) {
        this.addProjectInfoForm.lng = '' + data.point.lng;
        this.addProjectInfoForm.lat = '' + data.point.lat;
        this.addProjectInfoForm.address = data.address;
      } else {
        this.editProjectInfoForm.lng = '' + data.point.lng;
        this.editProjectInfoForm.lat = '' + data.point.lat;
        this.editProjectInfoForm.address = data.address;
      }
    },
    showLocationDialog(project) {
      this.mapCanEdit = false;
      this.markerPoit = {lng:project.lng, lat:project.lat};
      this.mapAddress = project.address;
      this.showMapDialogVisible = true;
    },
  }
}
</script>

<style lang="less" scoped>

</style>