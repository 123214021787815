<template>
  <div style="height: 100%; width: 100%;">
    <div style="width: 69%; height: 100%; float: left;">
      <div style="height: 50%; width: 100%; background-color: white; border-radius: 6px;" ref="checkFinishChartV"></div>
      <div style="height: 49%; width: 100%; background-color: white; border-radius: 6px; margin-top: 10px;" ref="repairFinishChartV"></div>
    </div>
    <div style="width: 30%; height: 100%; float: left; margin-left: 10px;">
      <div style="width: 100%; height: 29%; background-color: white; border-radius: 6px; text-align: center;">
        <img style="width: 95%; height: 95%; cursor: pointer;" src="../../assets/img/tuopu.png" @click="showImageDetail"/>
      </div>
      <div style="width: 100%; height: 70%; background-color: white; border-radius: 6px; margin-top: 10px;" ref="dangerChartV"></div>
    </div>

      <el-dialog
              title="巡检拓扑图"
              :visible.sync="showImageDetailVisable"
              width="70%"
              v-if="showImageDetailVisable"
            >
              <div style="height: 600px; width: 100%;">
                <img style="width: 95%; height: 95%; cursor: pointer;" src="../../assets/img/tuopu.png"/>
              </div>
            </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectInfoList: [],
      currentProjectInfo: null,
      selectYear: null,
      selectMonth: null,
      checkFinishChart: null,
      checkFinishChartData: [],
      repairFinishChart: null,
      repairFinishChartData: [],
      dangerChart: null,
      dangerChartData: [],
      showImageDetailVisable: false
    }
  },
  created() {
    this.getAllProject();
    this.selectYear = (new Date()).getFullYear();
    this.selectMonth = (new Date()).getMonth() + 1
  },
  methods: {
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
        if (this.projectInfoList != null) {
          this.currentProjectInfo = this.projectInfoList[0];
          this.loadData();
        }
      }
    },
    loadData() {
      this.loadCheckFinishData();
      this.loadRepairFinishData();
      this.loadDangerData();
    },
    // 获取巡检项完成度
    async loadCheckFinishData() {
      const { data: res } = await this.$http.get("report/check/finish", {
        params: {
          projectCode: this.currentProjectInfo.projectCode,
          year: this.selectYear,
          month: this.selectMonth,
          type: 2
        }
      });
      if (res.code == 0) {
        this.checkFinishChartData = res.data;
        this.drawCheckFinishView(this.checkFinishChartData);
      } else {
        this.$message.error(res.msg);
      }
    },
    drawCheckFinishView(dataList) {
      let that = this;
      if (this.checkFinishChart == null) {
        this.checkFinishChart = this.$echarts.init(this.$refs.checkFinishChartV);
        this.checkFinishChart.on('click', function (params) {
          that.toCheckPage(params.name);
        });
      }

      if (dataList === null || dataList.length === 0) {
        this.$message.error("无数据");
        // return;
        dataList = [];
      }

      var checkData = [];
      var checkTotalData = [];
      var checkX = [];
      dataList.forEach(data => {
        checkData.push(data.checked);
        checkTotalData.push(data.checkTotal);
        checkX.push(data.roleName);
      });

      var option;

      option = {
        title: {
          top: 30,
          left: 'center',
          text: this.selectYear + '年' + this.selectMonth + '月巡检完成度统计'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: 60,
          data: ['已巡检项', '应巡检项']
        },
        grid: {
          left: 40,
          right: 60,
          top: 100,
        },
        toolbox: {
          right: 30,
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            name: '级别',
            type: 'category',
            data: checkX
          }
        ],
        yAxis: [
          {
            name: '巡检项',
            type: 'value'
          }
        ],
        series: [
          {
            name: '已巡检项',
            type: 'bar',
            data: checkData,
            itemStyle: {
              color: '#91cc75'
            },
            label: {
              show: true
            }
          },
          {
            name: '应巡检项',
            type: 'bar',
            data: checkTotalData,
            label: {
              show: true
            }
          }
        ]
      };

      this.checkFinishChart.clear();
      option && this.checkFinishChart.setOption(option);
    },

    // 获取整改完成度数据
    async loadRepairFinishData() {
      const { data: res } = await this.$http.get("report/repair/finish", {
        params: {
          projectCode: this.currentProjectInfo.projectCode,
          year: this.selectYear,
          month: this.selectMonth,
          type: 2
        }
      });
      if (res.code == 0) {
        this.repairFinishChartData = res.data;
        this.drawRepairFinishView(this.repairFinishChartData);
      } else {
        this.$message.error(res.msg);
      }
    },
    drawRepairFinishView(dataList) {
      let that = this;
      if (this.repairFinishChart == null) {
        this.repairFinishChart = this.$echarts.init(this.$refs.repairFinishChartV);
        this.repairFinishChart.on('click', function (params) {
          that.toRepairPage(params.name);
        });
      }

      if (dataList === null || dataList.length === 0) {
        this.$message.error("无数据");
        // return;
        dataList = [];
      }

      var checkData = [];
      var checkTotalData = [];
      var checkX = [];
      dataList.forEach(data => {
        if (data.roleName === '公司级') {
          checkX[0] = data.roleName;
          checkData[0] = data.finished;
          checkTotalData[0] = data.mushFinish;
        } else if (data.roleName === '车间级') {
          checkX[1] = data.roleName;
          checkData[1] = data.finished;
          checkTotalData[1] = data.mushFinish;
        } else if (data.roleName === '工段级') {
          checkX[2] = data.roleName;
          checkData[2] = data.finished;
          checkTotalData[2] = data.mushFinish;
        } else if (data.roleName === '班组级') {
          checkX[3] = data.roleName;
          checkData[3] = data.finished;
          checkTotalData[3] = data.mushFinish;
        }
      });

      var option;

      option = {
        title: {
          top: 30,
          left: 'center',
          text: this.selectYear + '年' + this.selectMonth + '月到期整改完成度统计'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: 60,
          data: ['已整改完成项', '应整改完成项']
        },
        grid: {
          left: 40,
          right: 60,
          top: 100,
        },
        toolbox: {
          right: 30,
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            name: '级别',
            type: 'category',
            data: checkX
          }
        ],
        yAxis: [
          {
            name: '整改项',
            type: 'value'
          }
        ],
        series: [
          {
            name: '已整改完成项',
            type: 'bar',
            data: checkData,
            itemStyle: {
              color: '#91cc75'
            },
            label: {
              show: true
            }
          },
          {
            name: '应整改完成项',
            type: 'bar',
            data: checkTotalData,
            label: {
              show: true
            }
          }
        ]
      };

      this.repairFinishChart.clear();
      option && this.repairFinishChart.setOption(option);
    },

    // 获取安全隐患类型数据
    async loadDangerData() {
      const { data: res } = await this.$http.get("report/danger/data", {
        params: {
          projectCode: this.currentProjectInfo.projectCode,
          year: this.selectYear,
          month: this.selectMonth,
          type: 2
        }
      });
      if (res.code == 0) {
        this.dangerChartData = res.data;
        this.drawDangerView(this.dangerChartData);
      } else {
        this.$message.error(res.msg);
      }
    },
    drawDangerView(dataList) {
      let that = this;
      if (this.dangerChart == null) {
        this.dangerChart = this.$echarts.init(this.$refs.dangerChartV);
        this.dangerChart.on('click', function (params) {
          that.toDangerPage(params.name);
        });
      }

      if (dataList === null || dataList.length === 0) {
        this.$message.error("无数据");
        // return;
        dataList = [];
      }

      var dgTotalData = [];
      var checkX = [];
      dataList.forEach(data => {
        if (data.dgName === '无') {
          return;
        }
        dgTotalData.push(data.dgTotal);
        checkX.push(data.dgName);
      });

      var option;

      option = {
        title: {
          top: 30,
          left: 'center',
          text: this.selectYear + '年' + this.selectMonth + '月风险类型统计'
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: 100,
          right: 90,
          top: 100,
        },
        toolbox: {
          right: 30,
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            name: '数量',
            type: 'value'
          }
        ],
        yAxis: {
            name: '风险类型',
            type: 'category',
            inverse: true,
            data: checkX
          },
        series: [
          {
            type: 'bar',
            data: dgTotalData,
            itemStyle: {
              color: '#fac858'
            },
            label: {
              show: true
            }
          }
        ]
      };

      this.dangerChart.clear();
      option && this.dangerChart.setOption(option);
    },
    showImageDetail() {
      this.showImageDetailVisable = true;
    },
    toCheckPage(name) {
      this.$router.push({
        path: '/check/list',
        query: { name: name, year: this.selectYear, month: this.selectMonth, type: 1 },
      });
    },
    toDangerPage(name) {
      this.$router.push({
        path: '/check/list',
        query: { name: name, year: this.selectYear, month: this.selectMonth, type: 2 },
      });
    },
    toRepairPage(name) {
      this.$router.push({
        path: '/repair',
        query: { name: name, year: this.selectYear, month: this.selectMonth },
      });
    }
  }
}
</script>

<style></style>