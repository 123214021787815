<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>巡检作业管理</el-breadcrumb-item>
    </el-breadcrumb>


    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select v-model="queryParams.roleCode" placeholder="请选择级别" clearable @clear="getJobList" @change="getJobList">
                <el-option
                  v-for="item in checkLevelList"
                  :key="item.roleCode"
                  :label="item.roleName"
                  :value="item.roleCode">
                </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-radio-group v-model="queryParams.floodSeason" @input="getJobList">
            <el-radio :label="1">汛期</el-radio>
            <el-radio :label="0">非汛期</el-radio>
          </el-radio-group>
        </el-col>
        <el-col :span="16">
          <el-button 
            type="primary" 
            @click="showAddDialog" 
            v-has="'sys:role:add'"
            icon="el-icon-plus"
            >新增</el-button
          >
        </el-col>
      </el-row>


      <!-- 表格数据 -->
      <el-table :data="dataList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="级别" prop="roleName" width="120px"></el-table-column>
        <el-table-column label="作业名称" prop="jobName"></el-table-column>
        <el-table-column label="汛期/非汛期" prop="floodSeason" width="140px">
          <template slot-scope="scope">
            <span v-if="scope.row.floodSeason === 0">非汛期</span>
            <span v-else>汛期</span>
          </template>
        </el-table-column>
        <el-table-column label="周期" prop="cycle" width="80px">
          <template slot-scope="scope">
            {{ scope.row.cycle }}
            <span v-if="scope.row.cycleUnit === 1">日</span>
            <span v-else-if="scope.row.cycleUnit === 2">周</span>
            <span v-else>月</span>
          </template>
        </el-table-column>
        <el-table-column label="频次" prop="checkNum" width="80px">
          <template slot-scope="scope">
            {{ scope.row.checkNum }}次
          </template>
        </el-table-column>
        <el-table-column label="所属路线" prop="lineName"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="160px"></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row.id)"
                v-has="'sys:role:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeById(scope.row.id)"
                v-has="'sys:role:delete'"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>


      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>


      <!-- 添加数据 -->
      <el-dialog
        title="添加巡检作业"
        :visible.sync="addInfoDialogVisible"
        width="40%"
        @close="addDialogClosed"
        :append-to-body="true"
      >
        <el-form :model="addInfoForm" :rules="addRules" ref="addDataRef">
          <el-row>
            <el-col :span="12">
              <el-form-item label="级别" label-width="120px" prop="roleCode">
                <el-select v-model="addInfoForm.roleCode" placeholder="请选择">
                  <el-option
                    v-for="item in checkLevelList"
                    :key="item.roleCode"
                    :label="item.roleName"
                    :value="item.roleCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="路线" label-width="120px" prop="lineCode">
                <el-select v-model="addInfoForm.lineCode" placeholder="请选择">
                  <el-option
                    v-for="item in checkLineList"
                    :key="item.lineCode"
                    :label="item.lineName"
                    :value="item.lineCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="名称" label-width="120px" prop="jobName">
            <el-input
              autocomplete="off"
              v-model="addInfoForm.jobName"
              clearable
              placeholder="最多输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="" label-width="120px" prop="floodSeason">
            <el-radio-group v-model="addInfoForm.floodSeason">
              <el-radio :label="1">汛期（5月-9月）</el-radio>
              <el-radio :label="0">非汛期</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="巡检周期" label-width="120px" prop="cycle">
            <el-row>
              <!-- <el-col :span="4">
                <el-input
                  autocomplete="off"
                  v-model="cycleCount"
                  disabled
                ></el-input>
              </el-col>
              <el-col :span="5">
                <el-button-group>
                  <el-button icon="el-icon-minus" @click="decrease(0,1)"></el-button>
                  <el-button icon="el-icon-plus" @click="increase(0,1)"></el-button>
                </el-button-group>
              </el-col> -->
              <el-col :span="24">
                每
                <el-radio-group v-model="addInfoForm.cycleUnit">
                  <el-radio :label="1">日</el-radio>
                  <el-radio :label="2">周</el-radio>
                  <el-radio :label="3">月</el-radio>
                </el-radio-group>
                <span v-if="addInfoForm.cycleUnit === 2" style="margin-left: 20px; color: red;">选择“周”时，周日为第一天</span>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="巡检次数" label-width="120px" prop="checkNum">
            <el-row :gutter="10">
              <el-col :span="4">
                <el-input
                  autocomplete="off"
                  v-model="checkNumCount"
                  disabled
                ></el-input>
              </el-col>
              <el-col :span="5">
                <el-button-group>
                  <el-button icon="el-icon-minus" @click="decrease(1,1)"></el-button>
                  <el-button icon="el-icon-plus" @click="increase(1,1)"></el-button>
                </el-button-group>
              </el-col>
              <el-col :span="14">次</el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="提醒时间点" label-width="120px">
            <el-row v-for="(it, index) in addInfoForm.timePoint" :key="index">
              <el-col :span="1">第</el-col>
              <el-col :span="3"><el-input autocomplete="off" v-model="it.dayIndex"></el-input></el-col>
              <el-col :span="3">天</el-col>
              <el-col :span="3"><el-input autocomplete="off" v-model="it.h"></el-input></el-col>
              <el-col :span="3">时</el-col>
              <!-- <el-col :span="3"><el-input autocomplete="off" v-model="it.m"></el-input></el-col>
              <el-col :span="3">分</el-col>
              <el-col :span="3"></el-col> -->
            </el-row>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="addInfoForm.remark"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addInfoDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>


      <!-- 修改数据 -->
      <el-dialog
        title="修改巡检作业"
        :visible.sync="editDialogVisible"
        width="40%"
        @close="editDialogClosed"
        :append-to-body="true"
      >
        <el-form :model="editInfoForm" :rules="addRules" ref="editInfoRef">
          <el-row>
            <el-col :span="12">
              <el-form-item label="级别" label-width="120px" prop="roleCode">
                <el-select v-model="editInfoForm.roleCode" placeholder="请选择">
                  <el-option
                    v-for="item in checkLevelList"
                    :key="item.roleCode"
                    :label="item.roleName"
                    :value="item.roleCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="路线" label-width="120px" prop="lineCode">
                <el-select v-model="editInfoForm.lineCode" placeholder="请选择">
                  <el-option
                    v-for="item in checkLineList"
                    :key="item.lineCode"
                    :label="item.lineName"
                    :value="item.lineCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="名称" label-width="120px" prop="jobName">
            <el-input
              autocomplete="off"
              v-model="editInfoForm.jobName"
              clearable
              placeholder="最多输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="" label-width="120px" prop="floodSeason">
            <el-radio-group v-model="editInfoForm.floodSeason">
              <el-radio :label="1">汛期（5月-9月）</el-radio>
              <el-radio :label="0">非汛期</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="巡检周期" label-width="120px" prop="cycle">
            <el-row>
              <!-- <el-col :span="4">
                <el-input
                  autocomplete="off"
                  v-model="cycleCount"
                  disabled
                ></el-input>
              </el-col>
              <el-col :span="5">
                <el-button-group>
                  <el-button icon="el-icon-minus" @click="decrease(0,2)"></el-button>
                  <el-button icon="el-icon-plus" @click="increase(0,2)"></el-button>
                </el-button-group>
              </el-col> -->
              <el-col :span="24">
                每
                <el-radio-group v-model="editInfoForm.cycleUnit">
                  <el-radio :label="1">日</el-radio>
                  <el-radio :label="2">周</el-radio>
                  <el-radio :label="3">月</el-radio>
                </el-radio-group>
                <span v-if="editInfoForm.cycleUnit === 2" style="margin-left: 20px; color: red;">选择“周”时，周日为第一天</span>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="巡检次数" label-width="120px" prop="checkNum">
            <el-row :gutter="10">
              <el-col :span="4">
                <el-input
                  autocomplete="off"
                  v-model="checkNumCount"
                  disabled
                ></el-input>
              </el-col>
              <el-col :span="5">
                <el-button-group>
                  <el-button icon="el-icon-minus" @click="decrease(1,2)"></el-button>
                  <el-button icon="el-icon-plus" @click="increase(1,2)"></el-button>
                </el-button-group>
              </el-col>
              <el-col :span="14">次</el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="提醒时间点" label-width="120px">
            <el-row v-for="(it, index) in editInfoForm.timePoint" :key="index">
              <el-col :span="1">第</el-col>
              <el-col :span="3"><el-input autocomplete="off" v-model="it.dayIndex"></el-input></el-col>
              <el-col :span="3">天</el-col>
              <el-col :span="3"><el-input autocomplete="off" v-model="it.h"></el-input></el-col>
              <el-col :span="3">时</el-col>
              <!-- <el-col :span="3"><el-input autocomplete="off" v-model="it.m"></el-input></el-col>
              <el-col :span="3">分</el-col>
              <el-col :span="3"></el-col> -->
            </el-row>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="editInfoForm.remark"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addRules: {
        jobName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "最多只能输入20个字",
            trigger: "blur",
          },
        ],
        roleCode: [{ required: true, message: "请选择级别", trigger: "blur" }],
        lineCode: [{ required: true, message: "请选择路线", trigger: "blur" }],
        remark: [
          {
            min: 2,
            max: 50,
            message: "最多只能输入50个字",
            trigger: "blur",
          },
        ]
      },
      queryParams: {
        projectCode: "",
        roleCode: "",
        floodSeason: null,
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      addInfoForm: {
        projectCode:"",
        roleCode:"",
        jobName:"",
        lineCode:"",
        floodSeason: 0,
        remark:"",
        cycle: 1,
        cycleUnit: 1,
        checkNum: 1,
        timePoint: [{
          dayIndex: 1,
          h: 0,
          m: 0
        }]
      },
      editInfoForm: {

      },
      dataList: [],
      projectList: [],
      checkLevelList: [],
      checkLineList: [],
      currentProjectInfo: {},
      addInfoDialogVisible: false,
      editDialogVisible: false,
      cycleCount: 1,
      checkNumCount: 1,
    }
  },
  created() {
    this.getProjectList();
    this.getCheckLevelList();
  },
  methods: {
    decrease(t,action) {
      if (t === 0) {
        if (this.cycleCount > 1) {
          this.cycleCount--;
        }
      } else {
        if (this.checkNumCount > 1) {
          this.checkNumCount--;
          this.deleteTimePoint(action);
        }
      }
    },
    increase(t,action) {
      if (t === 0) {
        this.cycleCount++;
      } else {
        if (this.checkNumCount < 10) {
          this.checkNumCount++;
          this.addTimePoint(action);
        }
      }
    },
    addTimePoint (action) {
      if (action === 1) {
        // 新增操作
        this.addInfoForm.timePoint.push({
            dayIndex: 1,
            h: 0,
            m: 0
        });
      } else {
        // 修改
        this.editInfoForm.timePoint.push({
            dayIndex: 1,
            h: 0,
            m: 0
        });
      }
    },
    deleteTimePoint(action) {
      if (action === 1) {
        // 新增操作
        this.addInfoForm.timePoint.splice(this.addInfoForm.timePoint.length - 1, 1);
      } else {
        this.editInfoForm.timePoint.splice(this.editInfoForm.timePoint.length - 1, 1);
      }
    },
    async getCheckLevelList() {
      const { data: res } = await this.$http.get("role/find/check/level");
      if (res.code == 0) {
        this.checkLevelList = res.data;
      }
    },
    async getLineList() {
      const { data: res } = await this.$http.get("pro/check/line/list", {
        params: {
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        this.checkLineList = res.data;
      }
    },
    async getProjectList() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectList = res.data;
        this.currentProjectInfo = res.data[0];
        this.getJobList();
        this.getLineList();
      } else {
        this.$message.error(res.msg);
      }
    },
    async getJobList() {
      this.queryParams.projectCode = this.currentProjectInfo.projectCode;
      const { data: res } = await this.$http.get("checkjob/find/byPage", {
        params: this.queryParams,
      });
      if (res.code == 0) {
        this.dataList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    addDialogClosed() {
      this.$refs.addDataRef.resetFields();
    },
    editDialogClosed() {
      // this.$refs.editInfoRef.resetFields();
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getJobList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getJobList();
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("checkjob/find/" + id);
      if (res.code == 0) {
        this.editInfoForm = res.data;
        this.cycleCount = this.editInfoForm.cycle;
        this.checkNumCount = this.editInfoForm.checkNum;
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    editInfoSubmit() {
      this.editInfoForm.cycle = this.cycleCount;
      this.editInfoForm.checkNum = this.checkNumCount;
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "checkjob/update",
            this.editInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getJobList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("checkjob/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getJobList();
      } else {
        return this.$message.error("删除失败！");
      }
    },
    addInfoSubmit() {
      this.addInfoForm.projectCode = this.currentProjectInfo.projectCode;
      this.addInfoForm.cycle = this.cycleCount;
      this.addInfoForm.checkNum = this.checkNumCount;
      this.$refs.addDataRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "checkjob/add",
            this.addInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getJobList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addInfoDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    showAddDialog() {
      this.addInfoForm = {
        projectCode:"",
        roleCode:"",
        jobName:"",
        lineCode:"",
        floodSeason: 0,
        remark:"",
        cycle: 1,
        cycleUnit: 1,
        checkNum: 1,
        timePoint: [{
          dayIndex: 1,
          h: 0,
          m: 0
        }]
      }
      this.cycleCount = 1;
      this.checkNumCount = 1;
      this.addInfoDialogVisible = true;
    }
  }
}
</script>

<style lang="less" scoped>

</style>