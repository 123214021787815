<template>
    <div>
        <div class="item-record" @click="centerthis(data)">
            <div v-if="data.checkResultType === 0"
                style="width:100%;height: 28px; font-size: 18px;font-weight: bold;color: #409EFF;overflow: hidden;">
                {{ data.checkItemName }}</div>
            <div v-else style="width:100%;height: 28px; font-size: 18px;font-weight: bold;color: #F56C6C;overflow: hidden;">
                [{{ data.checkResult }}]{{ data.checkItemName }}</div>
            <div style="width:100%;height: 16px; font-size: 12px;color: #909399;overflow: hidden;">类型：{{ data.checkTypeName
            }}
            </div>
            <div style="width:100%;height: 16px; font-size: 12px;color: #909399;overflow: hidden;">巡检员：{{ data.userName }}
            </div>
            <div v-if="data.checkStatus === 2"
                style="width:100%;height: 16px; font-size: 12px;color: #909399;overflow: hidden;">{{ data.checkTime }}</div>
            <div v-else style="width:100%;height: 16px; font-size: 12px;color: #F56C6C;overflow: hidden;">未巡检</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data'],
    data() {
        return {

        }
    },
    methods: {
        centerthis(record) {
            this.$emit('showRecordDetail', record)
        }
    }
}
</script>

<style>
.item-record {
    width: 100%;
    height: 80px;
    padding: 5px;
    margin-bottom: 3px;
    cursor: pointer;
}
</style>