<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>报表统计</el-breadcrumb-item>
            <el-breadcrumb-item>巡检详情统计</el-breadcrumb-item>
        </el-breadcrumb>

        <el-card>
            <el-row :gutter="5">
                <el-col :span="3">
                    <el-select v-model="selectYear" placeholder="请选择年份">
                        <el-option label="2023" :value="2023"></el-option>
                        <el-option label="2024" :value="2024"></el-option>
                        <el-option label="2025" :value="2025"></el-option>
                        <el-option label="2026" :value="2026"></el-option>
                        <el-option label="2027" :value="2027"></el-option>
                        <el-option label="2028" :value="2028"></el-option>
                        <el-option label="2029" :value="2029"></el-option>
                        <el-option label="2030" :value="2030"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <el-select v-model="selectMonth" placeholder="请选择月份">
                        <el-option label="1月" :value="1"></el-option>
                        <el-option label="2月" :value="2"></el-option>
                        <el-option label="3月" :value="3"></el-option>
                        <el-option label="4月" :value="4"></el-option>
                        <el-option label="5月" :value="5"></el-option>
                        <el-option label="6月" :value="6"></el-option>
                        <el-option label="7月" :value="7"></el-option>
                        <el-option label="8月" :value="8"></el-option>
                        <el-option label="9月" :value="9"></el-option>
                        <el-option label="10月" :value="10"></el-option>
                        <el-option label="11月" :value="11"></el-option>
                        <el-option label="12月" :value="12"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <el-select v-model="selectQuarter" placeholder="请选择季度">
                        <el-option label="一季度(1月-3月)" :value="1"></el-option>
                        <el-option label="二季度(4月-6月)" :value="2"></el-option>
                        <el-option label="三季度(7月-9月)" :value="3"></el-option>
                        <el-option label="四季度(10月-12月)" :value="4"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="5">
                    <el-radio-group v-model="selectType">
                        <el-radio :label="1">按年</el-radio>
                        <el-radio :label="2">按月</el-radio>
                        <el-radio :label="3">按季度</el-radio>
                    </el-radio-group>
                </el-col>
                <el-col :span="7">
                    <el-button type="primary" icon="el-icon-search" @click="loadData">查询</el-button>
                    <el-button
                            @click="exportData"
                            style="margin-left: 10px"
                            type="success"
                            icon="el-icon-download"
                            >导出</el-button
                        >
                </el-col>
            </el-row>
            <el-row>
                <!-- 表格数据 -->
                <el-table :data="reportDataList" border stripe>
                    <el-table-column label="年月" width="100px">
                        <template slot-scope="scope">
                            {{ scope.row.reportYear }}年{{ scope.row.reportMonth }}月
                        </template>
                    </el-table-column>
                    <el-table-column label="级别" prop="roleName" width="80px"></el-table-column>
                    <el-table-column label="路线" prop="lineName"></el-table-column>
                    <el-table-column label="巡检员" prop="userName" width="120px"></el-table-column>
                    <el-table-column label="应巡检数" prop="msTotal" width="100px"></el-table-column>
                    <el-table-column label="已巡检数" prop="realTotal" width="100px"></el-table-column>
                    <el-table-column label="亲自巡检数" prop="selfTotal" width="100px"></el-table-column>
                    <el-table-column label="他人巡检数" prop="otherTotal" width="100px"></el-table-column>
                    <el-table-column label="未巡检数" prop="uncheckTotal" width="100px"></el-table-column>
                    <el-table-column label="发现风险数" prop="dangerTotal" width="100px"></el-table-column>
                    <!-- <el-table-column label="完成度">
                        <template slot-scope="scope">
                            {{ common.floatFormat(common.floatFormat((scope.row.checked / scope.row.checkTotal), 2) * 100, 2) }}%
                        </template>
                    </el-table-column> -->
                </el-table>

                <!-- 分页组件 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="pageNo" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            total: 0,
            checkFinishChart: null,
            selectYear: null,
            selectMonth: null,
            selectQuarter: 1,
            selectType: 2,
            reportDataList: [],
            currentProjectInfo: null
        }
    },
    created() {
        this.getAllProject();
        this.selectYear = (new Date()).getFullYear();
        this.selectMonth = (new Date()).getMonth() + 1
    },
    methods: {
        async getAllProject() {
            const { data: res } = await this.$http.get("project/find/all");
            if (res.code == 0) {
                this.projectInfoList = res.data;
                if (this.projectInfoList != null) {
                    this.currentProjectInfo = this.projectInfoList[0];
                    this.loadData();
                }
            }
        },
        handleSizeChange(newSize) {
            this.pageSize = newSize;
            this.loadData();
        },
        handleCurrentChange(newPage) {
            this.pageNo = newPage;
            this.loadData();
        },
        async loadData() {
            const { data: res } = await this.$http.get("report/user/check/detail", {
                params: {
                    projectCode: this.currentProjectInfo.projectCode,
                    year: this.selectYear,
                    month: this.selectMonth,
                    quarter: this.selectQuarter,
                    type: this.selectType,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                }
            });
            if (res.code == 0) {
                this.reportDataList = res.data.list;
                this.total = res.data.total;
            } else {
                this.$message.error(res.msg);
            }
        },
        exportData() {
            window.open(this.COMMON_CONFIG.HTTP_API_CTX + 'file/user/check/report/download?projectCode=' + this.currentProjectInfo.projectCode + 
                                                        '&year=' + this.selectYear + 
                                                        '&month=' + this.selectMonth + 
                                                        '&quarter=' + this.selectQuarter + 
                                                        '&type=' + this.selectType, '_blank');
        }
    }
}
</script>