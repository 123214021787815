<template>
  <div style="overflow-y: auto;height: calc(100vh - 100px);">
    <div class="detail-title">巡检信息</div>
    <el-form ref="form" label-width="80px">
      <el-form-item label="巡检路线">
        <span>{{ checklogInfo.lineName }}</span>
      </el-form-item>
      <el-form-item label="巡检项">
        <span>{{ checklogInfo.checkItemName }}</span>
      </el-form-item>
      <el-form-item label="巡检员">
        <span>{{ checklogInfo.userName }}</span>
      </el-form-item>
      <el-form-item label="公司陪同">
        <span>{{ gsString }}</span>
      </el-form-item>
      <el-form-item label="车间陪同">
        <span>{{ cjString }}</span>
      </el-form-item>
      <el-form-item label="工段陪同">
        <span>{{ gdString }}</span>
      </el-form-item>
      <el-form-item label="班组陪同">
        <span>{{ bzString }}</span>
      </el-form-item>
      <el-form-item label="其他陪同">
        <span>{{ checklogInfo.attUsers }}</span>
      </el-form-item>
      <el-form-item label="巡检内容">
        <span>{{ checklogInfo.checkContent }}</span>
      </el-form-item>
      <el-form-item label="巡检结果">
        <span>{{ checklogInfo.checkResult }}</span>
      </el-form-item>
      <el-form-item label="风险类型">
        <span>{{ checklogInfo.dgName }}</span>
      </el-form-item>
      <el-form-item label="巡检情况">
        <span>{{ checklogInfo.checkDetail }}</span>
      </el-form-item>
      <el-form-item label="巡检图片">
        <el-carousel :interval="5000" arrow="always" v-if="imageUrls.length > 0">
          <el-carousel-item v-for="item in imageUrls" :key="item">
            <el-image :src="item" fit="cover" :preview-src-list="imageUrls" :z-index="10000000"></el-image>
          </el-carousel-item>
        </el-carousel>
      </el-form-item>
    </el-form>
    <div v-if="logdetail.isRepair === 1">
      <div class="detail-title">整改信息</div>
      <el-form ref="form" label-width="80px">
        <el-form-item label="整改期限">
          <span>{{ common.getDuration(repairInfo.duration) }}</span>
        </el-form-item>
        <el-form-item label="安全员">
          <span>{{ repairInfo.secUserName }}</span>
        </el-form-item>
        <el-form-item label="巡检日期">
          <span>{{ repairInfo.checkDate }}</span>
        </el-form-item>
        <el-form-item label="截止日期">
          <span>{{ repairInfo.expiresFinishDate }}</span>
        </el-form-item>
        <el-form-item label="整改状态">
          <el-tag type="danger" v-if="repairInfo.repairStatus === 0">未整改</el-tag>
          <el-tag v-else-if="repairInfo.repairStatus === 1">整改中</el-tag>
          <el-tag v-else type="success">已整改</el-tag>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="logdetail.isRepair === 1 && repairInfo.repairStatus === 2">
      <div class="detail-title">整改反馈</div>
      <el-form ref="form" label-width="80px">
        <el-form-item label="完成日期">
          <span>{{ feedbackInfo.repairTime }}</span>
        </el-form-item>
        <el-form-item label="整改内容">
          <span>{{ feedbackInfo.repairContent }}</span>
        </el-form-item>
        <el-form-item label="整改图片">
          <el-carousel :interval="5000" arrow="always" v-if="fbImgUrls.length > 0">
            <el-carousel-item v-for="item in fbImgUrls" :key="item">
              <el-image :src="item" fit="cover" :preview-src-list="fbImgUrls" :z-index="10000000"></el-image>
            </el-carousel-item>
          </el-carousel>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logdetail: Object
  },
  inheritAttrs: false,
  data() {
    return {
      checklogInfo: {},
      repairInfo: {},
      feedbackInfo: {},
      imageUrls: [],
      fbImgUrls: [],
      gsString: '',
      cjString: '',
      gdString: '',
      bzString: ''
    }
  },
  created() {
    this.showCheckLogDetail(this.logdetail);
  },
  methods: {
    showCheckLogDetail(data) {
      if (data == null) {
        return;
      }

      this.findLogInfo(data);
    },
    async findLogInfo(data) {
      const { data: res } = await this.$http.get("pro/record/find/by/checkcode", {
        params: {
          checkCode: data.checkRecordCode
        }
      });
      if (res.code == 0) {
        this.checklogInfo = res.data;
        this.loadTogetherInfo(res.data.id);
        this.createImageUrls();
        if (this.checklogInfo.isRepair === 1) {
          // 有整改记录
          this.loadRepairInfo(this.checklogInfo.checkRecordCode);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    async loadTogetherInfo(checkId) {
      const { data: res } = await this.$http.get("pro/record/find/together", {
        params: {
          checkId: checkId
        }
      });
      if (res.code == 0) {
        var gs = [];
        var cj = [];
        var gd = [];
        var bz = [];
        res.data.forEach(d => {
          if (d.checkLevel === 1) {
            gs.push(d.userName);
          } else if (d.checkLevel === 2) {
            cj.push(d.userName);
          } else if (d.checkLevel === 3) {
            gd.push(d.userName);
          } else if (d.checkLevel === 4) {
            bz.push(d.userName);
          }
        });

        this.gsString = gs.join(",");
        this.cjString = cj.join(",");
        this.gdString = gd.join(",");
        this.bzString = bz.join(",");
      }
    },
    createImageUrls() {
      if (this.checklogInfo.imgUrl === null || this.checklogInfo.imgUrl === '') {
        return;
      }
      var im = (this.checklogInfo.imgUrl).split(",");
      this.imageUrls = im;
    },
    createFbImageUrls() {
      if (this.feedbackInfo.imgUrl === null || this.feedbackInfo.imgUrl === '') {
        return;
      }

      var im = (this.feedbackInfo.imgUrl).split(",");
      this.fbImgUrls = im;
    },
    async loadRepairInfo(checkCode) {
      const { data: res } = await this.$http.get("repair/find/by/checkcode", {
        params: {
          checkCode: checkCode
        }
      });
      if (res.code == 0) {
        this.repairInfo = res.data;
        if (this.repairInfo.repairStatus === 2) {
          //已整改
          this.loadFeedbackInfo(this.repairInfo.repairCode);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    async loadFeedbackInfo(repairCode) {
      const { data: res } = await this.$http.get("repair/find/feedback/by/repaircode", {
        params: {
          repairCode: repairCode
        }
      });
      if (res.code == 0) {
        this.feedbackInfo = res.data;
        this.createFbImageUrls();
      } else {
        this.$message.error(res.msg);
      }
    }
  }
}
</script>

<style>
/* .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  } */
.label-class {
  width: 120px;
}
.detail-title {
  width: 100%;
  height: 40px;
  background-color: #ecf5ff;
  text-indent: 1em;
  line-height: 40px;
  font-size: 16px;
}
</style>