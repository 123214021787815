<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>人员管理</el-breadcrumb-item>
      <el-breadcrumb-item>人员列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="10">
        <el-col :span="3">
          <el-select
            v-model="queryParams.orgCode"
            placeholder="请选择组织"
            clearable
            @clear="getUserList"
            @change="changeOrgSelect"
          >
            <el-option
              v-for="item in orgInfoList"
              :key="item.orgCode"
              :label="item.orgName"
              :value="item.orgCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="getUserList"
            @change="getUserList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-input
            placeholder="请输入姓名"
            v-model="queryParams.queryStr"
            clearable
            @clear="getUserList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getUserList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-button
            type="primary"
            @click="addUserDialogVisible = true"
            v-has="'sys:user:add'"
            icon="el-icon-plus"
            >添加人员</el-button
          >
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="userInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="账户" prop="account"></el-table-column>
        <el-table-column label="账户类型（仅显示）" width="140px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.accountType === 1">普通用户</el-tag>
            <el-tag v-else-if="scope.row.accountType === 2" type="danger"
              >管理员</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="所属角色" prop="roleName"></el-table-column>
        <el-table-column label="是否冻结" width="80px">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.lockedBl"
              @change="userStateChanged(scope.row)"
              v-has="'sys:user:edit'"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="姓名" prop="realName"></el-table-column>
        <el-table-column label="性别" width="80px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.gender === 0" type="danger">女</el-tag>
            <el-tag v-else-if="scope.row.gender === 1">男</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="微信名" prop="wxName"></el-table-column>
        <el-table-column label="操作" width="260px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="关联微信"
              placement="top"
              :enterable="false"
              v-if="scope.row.wxName === null || scope.row.wxName === ''"
            >
              <el-button
                type="success"
                icon="el-icon-chat-round"
                size="mini"
                @click="showBindWxDialog(scope.row.account)"
                v-has="'sys:user:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="解绑微信"
              placement="top"
              :enterable="false"
              v-else
            >
              <el-button
                type="danger"
                icon="el-icon-chat-round"
                size="mini"
                @click="unbindWx(scope.row.account)"
                v-has="'sys:user:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row.account)"
                v-has="'sys:user:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeById(scope.row.id)"
                v-has="'sys:user:delete'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="重置密码"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-key"
                size="mini"
                @click="resetPwdById(scope.row.accountId)"
                v-has="'sys:user:resetpwd'"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加人员 -->
    <el-dialog
      title="添加人员"
      :visible.sync="addUserDialogVisible"
      width="50%"
      @close="addUserInfoDialogClosed"
      @open="addUserInfoDialogOpen"
    >
      <el-form
        :model="addUserInfoForm"
        :rules="addUserRules"
        ref="addUserInfoRef"
      >
        <el-row>
          <el-col :span="11">
            <el-form-item
              label="组织"
              :label-width="formLabelWidth"
              prop="userDTO.orgCode"
            >
              <el-select
                v-model="addUserInfoForm.userDTO.orgCode"
                placeholder="请选择组织"
                clearable
                @change="addUserChangeOrgSelect"
              >
                <el-option
                  v-for="item in orgInfoList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2"> </el-col>
          <el-col :span="11">
            <el-form-item
              label="项目"
              :label-width="formLabelWidth"
              prop="userDTO.projectCode"
            >
              <el-select
                v-model="addUserInfoForm.userDTO.projectCode"
                placeholder="请选择项目"
                clearable
                @change="selectProjectInfo"
              >
                <el-option
                  v-for="item in projectInfoList"
                  :key="item.projectCode"
                  :label="item.projectName"
                  :value="item.projectCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
            <el-form-item label="路线" label-width="120px" prop="lineCode">
                  <el-select v-model="addUserInfoForm.userDTO.lineCode" placeholder="请选择" clearable>
                    <el-option
                      v-for="item in checkLineList"
                      :key="item.lineCode"
                      :label="item.lineName"
                      :value="item.lineCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item
              label="账号"
              :label-width="formLabelWidth"
              prop="accountDTO.account"
            >
              <el-input
                autocomplete="off"
                v-model="addUserInfoForm.accountDTO.account"
                clearable
              ></el-input
              ><span class="pwdTip">账号创建后不允许修改</span>
            </el-form-item>
          </el-col>
          <el-col :span="2"> </el-col>
          <el-col :span="11">
            <el-form-item label="密码" :label-width="formLabelWidth">
              <el-input
                autocomplete="off"
                v-model="addUserInfoForm.accountDTO.password"
                disabled
              ></el-input
              ><span class="pwdTip">请复制并保管好密码</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="账号类型" :label-width="formLabelWidth">
              <el-radio-group v-model="addUserInfoForm.accountDTO.accountType">
                <el-radio :label="1">普通用户</el-radio>
                <el-radio :label="2">管理员</el-radio> </el-radio-group
              ><br />
              <span style="color: rgb(175, 175, 175)">
                提示：客户方账号请选择“普通用户”类型。
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="2"> </el-col>
          <el-col :span="11">
            <el-form-item
              label="所属角色"
              :label-width="formLabelWidth"
              prop="accountDTO.roleId"
            >
              <el-select
                v-model="addUserInfoForm.accountDTO.roleId"
                placeholder="请选择角色"
                clearable
              >
                <el-option
                  v-for="item in roleInfoList"
                  :key="item.id"
                  :label="item.roleName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item
              prop="userDTO.realName"
              label="姓名"
              :label-width="formLabelWidth"
            >
              <el-input
                autocomplete="off"
                v-model="addUserInfoForm.userDTO.realName"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="11">
            <el-form-item
              prop="userDTO.gender"
              label="性别"
              :label-width="formLabelWidth"
            >
              <el-radio-group v-model="addUserInfoForm.userDTO.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item
              prop="accountDTO.phone"
              label="手机号"
              :label-width="formLabelWidth"
            >
              <el-input
                autocomplete="off"
                v-model="addUserInfoForm.accountDTO.phone"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="11">
            <el-form-item
              prop="accountDTO.email"
              label="邮箱"
              :label-width="formLabelWidth"
            >
              <el-input
                autocomplete="off"
                v-model="addUserInfoForm.accountDTO.email"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <v-addressSelector ref="addAddressRef" @addressChange="chengeAddress">
        </v-addressSelector>
        <el-form-item
          prop="userDTO.address"
          label="详细地址"
          :label-width="formLabelWidth"
        >
          <el-input
            autocomplete="off"
            v-model="addUserInfoForm.userDTO.address"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addUserDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUserInfoSubmit()">保 存</el-button>
      </span>
    </el-dialog>

    <!-- 修改人员 -->
    <el-dialog
      title="修改人员信息"
      :visible.sync="editUserDialogVisible"
      width="50%"
      @close="editUserInfoDialogClosed"
      @open="editUserInfoDialogOpen"
    >
      <el-form
        :model="editUserInfoForm"
        :rules="editUserRules"
        ref="editUserInfoRef"
      >
        <el-row>
          <el-col :span="11">
            <el-form-item
              label="组织"
              :label-width="formLabelWidth"
              prop="userDTO.orgCode"
            >
              <el-select
                v-model="editUserInfoForm.userDTO.orgCode"
                placeholder="请选择组织"
                clearable
                @change="editUserChangeOrgSelect"
              >
                <el-option
                  v-for="item in orgInfoList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2"> </el-col>
          <el-col :span="11">
            <el-form-item
              label="项目"
              :label-width="formLabelWidth"
              prop="userDTO.projectCode"
            >
              <el-select
                v-model="editUserInfoForm.userDTO.projectCode"
                placeholder="请选择项目"
                clearable
              >
                <el-option
                  v-for="item in projectInfoList"
                  :key="item.projectCode"
                  :label="item.projectName"
                  :value="item.projectCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
            <el-form-item label="路线" label-width="120px" prop="lineCode">
                  <el-select v-model="editUserInfoForm.userDTO.lineCode" placeholder="请选择" clearable>
                    <el-option
                      v-for="item in checkLineList"
                      :key="item.lineCode"
                      :label="item.lineName"
                      :value="item.lineCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
          </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="账号" :label-width="formLabelWidth">
              <el-input
                autocomplete="off"
                v-model="editUserInfoForm.accountDTO.account"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2"> </el-col>
          <el-col :span="11">
            <el-form-item label="账号类型" :label-width="formLabelWidth">
              <el-radio-group v-model="editUserInfoForm.accountDTO.accountType">
                <el-radio :label="1">普通用户</el-radio>
                <el-radio :label="2">管理员</el-radio> </el-radio-group
              ><br />
              <span style="color: rgb(175, 175, 175)">
                提示：客户方账号请选择“普通用户”类型。
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item
              label="所属角色"
              :label-width="formLabelWidth"
              prop="accountDTO.roleId"
            >
              <el-select
                v-model="editUserInfoForm.accountDTO.roleId"
                placeholder="请选择角色"
                clearable
              >
                <el-option
                  v-for="item in roleInfoList"
                  :key="item.id"
                  :label="item.roleName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item
              prop="userDTO.realName"
              label="姓名"
              :label-width="formLabelWidth"
            >
              <el-input
                autocomplete="off"
                v-model="editUserInfoForm.userDTO.realName"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="11">
            <el-form-item
              prop="userDTO.gender"
              label="性别"
              :label-width="formLabelWidth"
            >
              <el-radio-group v-model="editUserInfoForm.userDTO.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item
              prop="accountDTO.phone"
              label="手机号"
              :label-width="formLabelWidth"
            >
              <el-input
                autocomplete="off"
                v-model="editUserInfoForm.accountDTO.phone"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="11">
            <el-form-item
              prop="accountDTO.email"
              label="邮箱"
              :label-width="formLabelWidth"
            >
              <el-input
                autocomplete="off"
                v-model="editUserInfoForm.accountDTO.email"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <v-addressSelector
          @addressChange="chengeAddress"
          :selectedProvinceCode="selectedProvinceCode"
          :selectedProvinceName="selectedProvinceName"
          :selectedCityCode="selectedCityCode"
          :selectedCityName="selectedCityName"
          :selectedAreaCode="selectedAreaCode"
          :selectedAreaName="selectedAreaName"
        >
        </v-addressSelector>
        <el-form-item
          prop="userDTO.address"
          label="详细地址"
          :label-width="formLabelWidth"
        >
          <el-input
            autocomplete="off"
            v-model="editUserInfoForm.userDTO.address"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editUserDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfoSubmit()"
          >保 存</el-button
        >
      </span>
    </el-dialog>

    <!-- 重置密码成功 -->
    <el-dialog
      title="重置密码成功"
      :visible.sync="resetPwdDialogVisible"
      width="30%"
    >
      <span
        >请复制新密码：<span style="color: red">{{
          addUserInfoForm.accountDTO.password
        }}</span></span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetPwdDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="resetPwdDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 添加用户 -->
    <el-dialog
          title="添加用户"
          :visible.sync="showBindWxDialogVisible"
          v-if="showBindWxDialogVisible"
          width="60%"
        >
          <v-wxuser-table
            :bindUser="true"
            @pjbind="bindUser"
            ref="wxuserRef"
          ></v-wxuser-table>
        </el-dialog>
  </div>
</template>

<script>
import vAddressSelector from '../../addressSelector/AddressSelector.vue'
import vWxuserTable from "../weixin/WxuserTable.vue"
export default {
  components: {
    vAddressSelector,
    vWxuserTable
  },
  data () {
    // 自定义邮箱规则
    var checkEmail = (rule, value, callback) => {
      if (value == null) {
        return callback()
      }
      if (value.length == 0) {
        return callback()
      }

      const regEmail = /^\w+@\w+(\.\w+)+$/
      if (regEmail.test(value)) {
        // 合法邮箱
        return callback()
      }
      callback(new Error('请输入合法邮箱'))
    }
    // 新增人员时验证
    var addCheckEmailExist = async (rule, value, callback) => {
      if (value == null) {
        return callback()
      }

      if (value.length == 0) {
        return callback()
      }

      // 验证平台是否已存在该邮箱
      const { data: res } = await this.$http.get('user/email/exist?email=' + value);
      if (res.code == 0) {
        // 未被使用时返回0
        if (res.data == 0) {
          return callback()
        }
      }

      callback(new Error('邮箱已经被使用'))
    }
    // 修改人员时验证
    var editCheckEmailExist = async (rule, value, callback) => {
      if (value == null) {
        return callback()
      }
      if (value.length == 0) {
        return callback()
      }
      // 验证平台是否已存在该邮箱
      const { data: res } = await this.$http.get('user/email/exist', {
        params: {
          email: value,
          accountId: this.editUserInfoForm.accountDTO.id
        }
      });
      if (res.code == 0) {
        // 未被使用时返回0
        if (res.data == 0) {
          return callback()
        }
      }

      callback(new Error('邮箱已经被使用'))
    }
    // 自定义手机号规则
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/
      if (regMobile.test(value)) {
        return callback()
      }
      // 返回一个错误提示
      callback(new Error('请输入合法的手机号码'))
    }
    // 新增人员时验证
    var addCheckMobileExist = async (rule, value, callback) => {
      const { data: res } = await this.$http.get('user/phone/exist?phone=' + value);
      if (res.code == 0) {
        // 未被使用时返回0
        if (res.data == 0) {
          return callback()
        }
      }

      // 返回一个错误提示
      callback(new Error('手机号码已经被使用'))
    }
    // 编辑人员时验证
    var editCheckMobileExist = async (rule, value, callback) => {
      const { data: res } = await this.$http.get('user/phone/exist', {
        params: {
          phone: value,
          accountId: this.editUserInfoForm.accountDTO.id
        }
      });
      if (res.code == 0) {
        // 未被使用时返回0
        if (res.data == 0) {
          return callback()
        }
      }

      // 返回一个错误提示
      callback(new Error('手机号码已经被使用'))
    }
    // 验证账号是否存在
    var checkAccountExist = async (rule, value, callback) => {
      const { data: res } = await this.$http.get('user/account/exist?account=' + value);
      if (res.code == 0) {
        // 未被使用时返回0
        if (res.data == 0) {
          return callback()
        }
      }

      // 返回一个错误提示
      callback(new Error('账号已经被使用'))
    }
    return {
      selectedProvinceCode: '',
      selectedProvinceName: '',
      selectedCityCode: '',
      selectedCityName: '',
      selectedAreaCode: '',
      selectedAreaName: '',
      addUserRules: {
        accountDTO: {
          roleId: [{ required: true, message: "请选择角色", trigger: "blur" }],
          account: [{ required: true, message: "请输入账号", trigger: "blur" },
          { validator: checkAccountExist, trigger: 'blur' }],
          phone: [{ required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkMobile, trigger: 'blur' },
          { validator: addCheckMobileExist, trigger: 'blur' }],
          email: [{ validator: checkEmail, trigger: 'blur' },
          { validator: addCheckEmailExist, trigger: 'blur' }]
        },
        userDTO: {
          realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
          // provinceCode: [{ required: true, message: "请选择省份", trigger: "blur" }],
          // cityCode: [{ required: true, message: "请选择城市", trigger: "blur" }],
          orgCode: [{ required: true, message: "请选择组织", trigger: "blur" }],
          projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        }
      },
      editUserRules: {
        accountDTO: {
          roleId: [{ required: true, message: "请选择角色", trigger: "blur" }],
          phone: [{ required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkMobile, trigger: 'blur' },
          { validator: editCheckMobileExist, trigger: 'blur' }],
          email: [{ validator: checkEmail, trigger: 'blur' },
          { validator: editCheckEmailExist, trigger: 'blur' }]
        },
        userDTO: {
          realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
          provinceCode: [{ required: true, message: "请选择省份", trigger: "blur" }],
          cityCode: [{ required: true, message: "请选择城市", trigger: "blur" }],
          orgCode: [{ required: true, message: "请选择组织", trigger: "blur" }],
          projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        }
      },
      queryParams: {
        orgCode: null,
        projectCode: null,
        queryStr: "",
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      orgInfoList: [],
      projectInfoList: [],
      addUserDialogVisible: false,
      editUserDialogVisible: false,
      showBindWxDialogVisible: false,
      userInfoList: [],
      addUserInfoForm: {
        accountDTO: {
          roleId: null,
          account: "",
          email: null,
          phone: "",
          accountType: 1,
          createTime: "",
          password: ""
        },
        userDTO: {
          orgCode: null,
          projectCode: null,
          realName: "",
          gender: 0,
          address: "",
          provinceName: "",
          provinceCode: "",
          cityName: "",
          cityCode: "",
          areaName: "",
          areaCode: "",
        }
      },
      editUserInfoForm: {
        accountDTO: {},
        userDTO: {}
      },
      provinceList: [],
      cityList: [],
      areaList: [],
      roleInfoList: [],
      checkLineList: [],
      formLabelWidth: '120px',
      resetPwdDialogVisible: false,
      currentSelectBindAccount: null
    }
  },
  created () {
    this.getOrgList();
    this.getUserList();
  },
  mounted() {
    this.getOrgList();
    this.getUserList();
  },
  methods: {
    async getOrgList () {
      const { data: res } = await this.$http.get("org/find/all");
      if (res.code == 0) {
        this.orgInfoList = res.data;
      }
    },
    async getUserList () {
      const { data: res } = await this.$http.get("user/find/user/byPage", {
        params: this.queryParams,
      });
      if (res.code == 0) {
        this.userInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    changeOrgSelect () {
      this.getUserList();
      this.loadProjectListByOrgId(this.queryParams.orgCode);
    },
    addUserChangeOrgSelect () {
      this.loadProjectListByOrgId(this.addUserInfoForm.userDTO.orgCode);
    },
    editUserChangeOrgSelect () {
      this.loadProjectListByOrgId(this.editUserInfoForm.userDTO.orgCode);
    },
    async loadProjectListByOrgId (orgCode) {
      const { data: res } = await this.$http.get("project/find/list/byOrg", {
        params: {
          orgCode: orgCode
        }
      });
      if (res.code == 0) {
        this.projectInfoList = res.data;
      }
    },
    async generatePwd () {
      const { data: res } = await this.$http.get("user/generate/password");
      if (res.code == 0) {
        this.addUserInfoForm.accountDTO.password = res.data;
      }
    },
    async userStateChanged (userInfo) {
      const confirmResult = await this.$confirm(
        "此操作将禁止用户登录, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        userInfo.lockedBl = !userInfo.lockedBl;
        return false;
      }

      const { data: res } = await this.$http.get("user/locked", {
        params: {
          accountId: userInfo.accountId,
          lock: userInfo.lockedBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeById (id) {
      const confirmResult = await this.$confirm("是否确认删除该人员?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("user/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getUserList();
      } else {
        return this.$message.error("删除失败！");
      }
    },
    async resetPwdById (accountId) {
      const confirmResult = await this.$confirm(
        "是否需要重置密码?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        return false;
      }

      const { data: res } = await this.$http.get("user/pwd/reset/" + accountId);
      if (res.code == 0) {
        this.resetPwdDialogVisible = true;
        this.addUserInfoForm.accountDTO.password = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async showEditDialog (account) {
      const { data: res } = await this.$http.get("user/find/detail/" + account);
      if (res.code == 0) {
        this.editUserInfoForm = res.data;
        this.selectedProvinceCode = res.data.userDTO.provinceCode;
        this.selectedProvinceName = res.data.userDTO.provinceName;
        this.selectedCityCode = res.data.userDTO.cityCode;
        this.selectedCityName = res.data.userDTO.cityName;
        this.selectedAreaCode = res.data.userDTO.areaCode;
        this.selectedAreaName = res.data.userDTO.areaName;
        this.editUserDialogVisible = true;
        this.loadProjectListByOrgId(this.editUserInfoForm.userDTO.orgCode);
        this.selectProjectInfo(res.data.userDTO.projectCode);
      } else {
        this.$message.error(res.msg);
      }
    },
    editUserInfoDialogOpen () {
      this.getRoleList();
    },
    editUserInfoDialogClosed () {
      this.$refs.editUserInfoRef.resetFields();
    },
    editUserInfoSubmit () {
      this.$refs.editUserInfoRef.validate(async (valid) => {
        if (valid) {
          this.editUserInfoForm.userDTO.provinceCode = this.selectedProvinceCode;
          this.editUserInfoForm.userDTO.provinceName = this.selectedProvinceName;
          this.editUserInfoForm.userDTO.cityCode = this.selectedCityCode;
          this.editUserInfoForm.userDTO.cityName = this.selectedCityName;
          this.editUserInfoForm.userDTO.areaCode = this.selectedAreaCode;
          this.editUserInfoForm.userDTO.areaName = this.selectedAreaName;
          const { data: res } = await this.$http.post(
            "user/update",
            this.editUserInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getUserList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editUserDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getUserList();
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getUserList();
    },
    addUserInfoDialogOpen () {
      this.generatePwd();
      this.getRoleList();
    },
    addUserInfoDialogClosed () {
      // console.log(this.$refs);
      this.$refs.addAddressRef.clearData();
      this.$refs.addUserInfoRef.resetFields();
    },
    async addUserInfoSubmit () {
      this.$refs.addUserInfoRef.validate(async (valid) => {
        if (valid) {
          this.addUserInfoForm.userDTO.provinceCode = this.selectedProvinceCode;
          this.addUserInfoForm.userDTO.provinceName = this.selectedProvinceName;
          this.addUserInfoForm.userDTO.cityCode = this.selectedCityCode;
          this.addUserInfoForm.userDTO.cityName = this.selectedCityName;
          this.addUserInfoForm.userDTO.areaCode = this.selectedAreaCode;
          this.addUserInfoForm.userDTO.areaName = this.selectedAreaName;
          const { data: res } = await this.$http.post(
            "user/add",
            this.addUserInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getUserList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addUserDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async getRoleList () {
      const { data: res } = await this.$http.get("role/find/all", {
        params: this.queryParams,
      });
      if (res.code == 0) {
        this.roleInfoList = res.data;
      }
    },
    chengeAddress (data) {
      this.selectedProvinceCode = data.provinceCode;
      this.selectedProvinceName = data.provinceName;
      this.selectedCityCode = data.cityCode;
      this.selectedCityName = data.cityName;
      this.selectedAreaCode = data.areaCode;
      this.selectedAreaName = data.areaName;
    },
    async getLineList(data) {
      const { data: res } = await this.$http.get("pro/check/line/list", {
        params: {
          projectCode: data
        }
      });
      if (res.code == 0) {
        this.checkLineList = res.data;
      }
    },
    selectProjectInfo(data) {
      this.getLineList(data);
    },
    showBindWxDialog(data) {
      this.currentSelectBindAccount = data;
      this.showBindWxDialogVisible = true;
    },
    async bindUser(data) {
      const { data: res } = await this.$http.get("wx/bind/user", {
        params: {
          account: this.currentSelectBindAccount,
          openid: data
        }
      });
      if (res.code == 0) {
        this.getUserList();
        this.showBindWxDialogVisible = false;
        this.$message.success("绑定成功");
      } else {
        this.$message.error("绑定失败");
      }
    },
    async unbindWx(data) {
      const { data: res } = await this.$http.get("wx/unbind/user", {
        params: {
          account: data
        }
      });
      if (res.code == 0) {
        this.getUserList();
        this.$message.success("操作成功");
      } else {
        this.$message.error("绑定失败");
      }
    }
  }
}
</script>

<style lang="less" scoped>
.pwdTip {
  color: rgb(175, 175, 175);
}
</style>