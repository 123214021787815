<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">智能巡检平台</div>
      <el-form
        :model="param"
        :rules="rules"
        ref="login"
        label-width="0px"
        class="ms-content"
      >
        <el-form-item prop="account">
          <el-input
            v-model="param.account"
            placeholder="请输入用户名"
            prefix-icon="el-icon-user"
            @keydown.enter.native="enterKey"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="param.password"
            @keydown.enter.native="enterKey"
            prefix-icon="el-icon-lock"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="verifyCode" class="verifyCode">
          <div style="display: flex; width: 100%">
            <el-input
              v-model="param.verifyCode"
              placeholder="输入验证码"
              @keydown.enter.native="enterKey"
            >
            </el-input>
            <el-image
              :src="verifyCodeImgUrl"
              class="verifyCodeImg"
              @click="refreshCode()"
            ></el-image>
          </div>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" @click="submitForm()">登录</el-button>
        </div>
      </el-form>
    </div>
    <div class="footright"></div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      param: {
        account: "",
        password: "",
        verifyCode: "",
      },
      rules: {
        account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        verifyCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      verifyCodeImgUrl: ""
    };
  },
  created () {
    this.refreshCode();
  },
  methods: {
    enterKey (e) {
      if (e.keyCode == 13) {
        this.submitForm();
        console.log("回车...");
      }
    },
    submitForm () {
      this.$refs.login.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post("login", this.param);
          if (res.code == 0) {
            this.$message.success("登录成功");
            localStorage.setItem("ms_username", this.param.account);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("user_perms", res.data.perms);
            localStorage.setItem("gender", res.data.gender);
            localStorage.setItem("realName", res.data.realName);
            this.$router.push("/home");
          } else {
            this.$message.error(res.msg);
            return false;
          }
        } else {
          this.$message.error("请输入账号和密码");
          console.log("error submit!!");
          return false;
        }
      });
    },
    refreshCode () {
      this.verifyCodeImgUrl = this.COMMON_CONFIG.HTTP_API_CTX + "verifyCode?" + Math.random();
      // console.log("刷新验证码.");
    }
  },
};
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/login-bg2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  color: #303133;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
.verifyCode {
  width: 100%;
}
.verifyCodeImg {
  width: 50%;
  height: 35px;
  margin-left: 10px;
}
.footright {
  position: absolute;
  width: 100%;
  height: 30px;
  text-align: center;
  bottom: 50px;
  color: white;
  font-size: 14px;
}
</style>