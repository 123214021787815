<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>整改记录</el-breadcrumb-item>
      <el-breadcrumb-item>整改列表</el-breadcrumb-item>
    </el-breadcrumb>


    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="10">
        <el-col :span="3">
            <el-select
              v-model="queryParams.roleCode"
              placeholder="请选择级别"
              clearable
            >
            <el-option
                      v-for="item in checkLevelList"
                      :key="item.roleCode"
                      :label="item.roleName"
                      :value="item.roleCode">
                    </el-option>
          </el-select>
            </el-col>
        <el-col :span="3">
          <el-select
            v-model="queryParams.repairStatus"
            placeholder="请选择整改状态"
            clearable
          >
          <el-option
              label="未整改"
              :value="0"
            ></el-option>
            <el-option
              label="整改中"
              :value="1"
            ></el-option>
            <el-option
              label="已整改"
              :value="2"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-date-picker
            v-model="timeRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="triggerDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="7">
          <el-button 
            type="primary" 
            icon="el-icon-search"
            @click="getDataList"
            >查询</el-button
          >
          <el-button
            @click="exportData"
            style="margin-left: 10px"
            type="success"
            icon="el-icon-download"
            >导出</el-button
          >
        </el-col>
      </el-row>


      <!-- 表格数据 -->
      <el-table :data="dataInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="级别" prop="roleName"></el-table-column>
        <el-table-column label="巡检项" prop="checkItemName"></el-table-column>
        <el-table-column label="巡检日期" prop="checkDate"></el-table-column>
        <el-table-column label="整改截止日期" prop="expiresFinishDate"></el-table-column>
        <el-table-column label="整改期限" prop="duration">
          <template slot-scope="scope">
            {{ common.getDuration(scope.row.duration) }}
          </template>
        </el-table-column>
        <el-table-column label="安全员" prop="secUserName"></el-table-column>
        <el-table-column label="整改状态" prop="repairStatus">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.repairStatus === 0">未整改</el-tag>
            <el-tag v-else-if="scope.row.repairStatus === 1">整改中</el-tag>
            <el-tag v-else type="success">已整改</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="160px"></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="详情"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-document"
                size="mini"
                @click="showDetail(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
              v-if="scope.row.repairStatus !== 2"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="remove(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>


      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>


    <el-drawer
      v-if="drawer"
      title="详细信息"
      :visible.sync="drawer"
      size="40%"
      append-to-body>
      <v-check-detail ref="logdetailRef" :logdetail="selectLogdetail"></v-check-detail>
    </el-drawer>
  </div>
</template>

<script>
import vCheckDetail from "../checkdetail/CheckDetail.vue";
export default {
  components: {
    vCheckDetail
  },
  data() {
    return {
      drawer: false,
      queryParams: {
        projectCode: null,
        repairStatus: null,
        pageNo: 1,
        pageSize: 10,
        checkBeginDate: null,
        checkEndDate: null,
        roleCode: null
      },
      total: 0,
      dataInfoList: [],
      timeRange: [],
      projectInfoList: [],
      selectLogdetail: {},
      checkLevelList: [],
      isRouter: false
    }
  },
  created() {
    this.getAllProject();
    this.getCheckLevelList();
  },
  mounted() {
    if (this.$route.query.name) {
      this.isRouter = true;
    } else {
      this.isRouter = false;
    }
  },
  methods: {
    async getCheckLevelList() {
      const { data: res } = await this.$http.get("role/find/check/level");
      if (res.code == 0) {
        this.checkLevelList = res.data;
        if (this.isRouter) {
          this.showFromReport(this.$route.query);
        }
      }
    },
    triggerDate (date) {
      if (!this.common.triggerDate(date, 90)) {
        this.$message.error('查询时间不能超过90天！');
        return false;
      } else {
        return true;
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getDataList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getDataList();
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
        if (this.projectInfoList != null) {
          this.queryParams.projectCode = this.projectInfoList[0].projectCode;
          if (!this.isRouter) {
            this.loadLastWeekData();
          }
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    // 获取过去一个星期的数据
    loadLastWeekData() {
      var today = new Date();
      // 天数所表示的毫秒
      var dateOffset = 30 * 24 * 60 * 60 * 1000;
      var startDate = new Date(today.getTime() - dateOffset);
      this.timeRange.push(new Date(Date.parse(this.common.todayDateStr('YYYY-MM-dd HH:mm:ss', startDate))));
      this.timeRange.push(new Date(Date.parse(this.common.todayDateStr('YYYY-MM-dd HH:mm:ss', today))));
      this.getDataList();
    },
    async getDataList () {
      if (!this.triggerDate(this.timeRange)) {
        return;
      }

      if (this.timeRange != null && this.timeRange.length > 0) {
        this.queryParams.checkBeginDate = this.common.dateFormat('YYYY-MM-dd', this.timeRange[0]);
        this.queryParams.checkEndDate = this.common.dateFormat('YYYY-MM-dd', this.timeRange[1]);
      } else {
        this.queryParams.checkBeginDate = null;
        this.queryParams.checkEndDate = null;
      }

      const { data: res } = await this.$http.post("repair/search/record", this.queryParams);
      if (res.code == 0) {
        this.dataInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    showDetail(record) {
      this.findLogInfo(record);
    },
    async findLogInfo(data) {
      const { data: res } = await this.$http.get("pro/record/find/by/checkcode", {
        params: {
          checkCode: data.checkRecordCode
        }
      });
      if (res.code == 0) {
        this.selectLogdetail = res.data;
        this.drawer = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async remove(id) {
      const confirmResult = await this.$confirm("是否确认删除该记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("repair/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getDataList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    exportData() {
      if (!this.triggerDate(this.timeRange)) {
        return;
      }

      window.open(this.COMMON_CONFIG.HTTP_API_CTX + 'file/repair/download?projectCode=' + this.queryParams.projectCode + '&repairStatus=' + this.queryParams.repairStatus + '&checkBeginDate=' + this.queryParams.checkBeginDate + '&checkEndDate=' + this.queryParams.checkEndDate, '_blank');
    },
    showFromReport(params) {
      var roleCode = '';
      this.checkLevelList.forEach(lv => {
        if (lv.roleName === params.name) {
          roleCode = lv.roleCode;
        }
      });

      this.queryParams.roleCode = roleCode;
      const endOfMonth = new Date(params.year, params.month, 0).getDate();
      let month = params.month;
      let startDay = 1;
      let endDay = endOfMonth;
      if (month < 10)  month = '0' + month
      if (startDay < 10)  startDay = '0' + startDay
      if (endDay < 10)  endDay = '0' + endDay
      var startTime = params.year + '-' + month + '-' + startDay + " " + "00:00:00";
      var endTime = params.year + '-' + month + '-' + endDay + " " + "00:00:00";
      this.timeRange.push(new Date(Date.parse(startTime)));
      this.timeRange.push(new Date(Date.parse(endTime)));
      this.getDataList();
    }
  }
}
</script>

<style lang="less" scoped>

</style>